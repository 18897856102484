.login-left {
    float: left;
    width: 500px;
    margin-left: 11%;
}

.login-left .mainlogo {
    text-align: left
}

.mainlogo img {
    max-width: 100%;
    height: 80px
}

.login-bg .login-block {
    margin: 0px;
    margin-right: 11%;
}

.exceltbl .react-tel-input input[type=text],
.exceltbl .react-tel-input input[type=tel] {
    height: 40px !important;
    background: #fff !important;
}

.form-group .react-tel-input input[type=text],
.form-group .react-tel-input input[type=tel] {
    height: 40px !important;
}

.upload-message {
    background-color: #c8e6dc;
    padding: 11px;
}

.navbar .navbar-brand img {
    height: 46px;
}

.admin-side-menu a {
    padding: 12px 12px;
    margin-bottom: 8px;
}

.exceltbl table th,
.exceltbl table td {
    border-right: 1px solid #bfbfbf !important;
    position: relative;
}

.react-tel-input .flag-dropdown {
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
}

.toggle-label .label {
    display: inline-block;
    position: relative;
    width: 33.33%;
}

.product-logo {
    max-width: 200px;
}

#pack-accordion .card .card-body,
.pack-accordion-head[aria-expanded=true] {
    background: #ffffff;
}

.slick-card {
    overflow: hidden;
    color: #2E384D;
    background-color: #fff;
    padding: 16px 0 14px 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -14px;
}

.slick-card span {
    padding-right: 40px;
    padding-left: 40px;
    border-right: 1px solid #d3d4d5;
    color: #2e744c;
}

.btn.disabled,
.btn:disabled {
    opacity: .25;
    cursor: not-allowed;
}

.login-bg {
    background: #f5f5f5;
    height: 100vh;
    padding-top: 170px;
}

.login-bg .login-block {
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.1);
}

.login-left {
    line-height: 32px;
}

.login-left .mainlogo {
    margin-left: -6px;
}

.summary-legends li:before {
    background: none !important;
}

.right-graph-links li.active {
    color: #454d66;
    font-weight: 600;
}

.right-graph-links li {
    font-size: 12px;
    display: inline-block;
    margin-right: 20px;
    color: #707070;
    cursor: pointer;
}

.case_initiation_accordion .nav-link {
    cursor: pointer;
}

.toggle-label-new .label {
    width: 50% !important;
}

.toggle-label-new {
    width: 280px !important;
}

.otp-inputs .form-control {
    font-size: 24px !important;
    margin-bottom: 10px;
}

.left-content {
    background: #ffffff;
    min-height: 80vh;
    margin-top: 4rem;
    padding: 24px;
}

.left-content h6 {
    font-weight: 500;
}

.case-initiaion-cards .user-card {
    background-color: #fff !important;
}

.case-initiaion-cards .user-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
}

.nav-link {
    cursor: pointer;
}

.view-docs-form .tag-list li {
    min-width: 120px;
    background: #eceef3;
    border-radius: 20px;
    padding: 8px 16px;
    margin-right: 10px;
    font-size: 14px;
    min-width: 106px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
}

.basic-details .react-tel-input input[type=tel] {
    width: 100%;
}

.bgvform .react-tel-input input[type=tel] {
    width: 100% !important;
}

.right-content .card .pack-accordion-head h6 .sub-heading {
    font-size: 14px;
    margin-top: 8px;
}

.add-document-modal.cibil-popup .modal-body {
    padding-top: 0px;
}

.add-document-modal.cibil-popup>.modal-dialog {
    width: 50%;
}

.form-preview-container .candidate-card {
    margin-bottom: 30px;
}

.form-group .optional {
    display: inline-block;
}

.admin-row .right-side {
    background-color: #f6f8fb !important;
}

.add-document-modal.reference-popup .modal-body {
    padding-top: 0px;
}

.add-document-modal.reference-popup>.modal-dialog {
    width: 60%;
}

.add-link {
    float: right;
    margin-top: 5px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.add_newbreak {
    width: 100%;
    margin-bottom: 10px;
}

.add_newbreak button {
    float: right;
    margin: 0px 15px;
}

.z_index_0 .react-tel-input .selected-flag {
    z-index: 0;
}

.showerrormsg {
    text-align: center;
    color: #fb0000f7;
    padding-bottom: 10px;
}

.nav-item button:focus {
    outline: none;
}

.btn-link {
    background: transparent;
    outline: none;
    border: 0px;
}

#employmentTemplateTable .hide,
#verifierDetailsTable .hide {
    display: none !important;
}

#employmentTemplateTable .block,
#verifierDetailsTable .block {
    display: block !important;
}

#verifierDetailsTable tbody tr:first-child td {
    border-top: none;
}

/*sivanesh css */

.subheader-nav {
    box-shadow: none;
}

.subheader-nav .responsive-navbar-nav {
    border-bottom: 1px solid #dee2e6;
}

.subheader-nav {
    padding: 0px !important;
}

.location p {
    width: calc(100% - 35px) !important;
}

.btn_disabled {
    cursor: not-allowed !important;
    opacity: 0.9;
}

.container-fluid .entity-card {
    margin-top: 40px !important;
    padding: 0px;
}

.checkclass .container_Checkbox.roundedcheckbox {
    margin-top: 9px;
}

.inline-group p {
    display: inline;
    margin-right: 20px;
    width: 170px;
    opacity: 0.7;
    margin-left: 30px;
}

.inline-group input {
    display: inline;
    width: 160px;
    background: #fff;
}

.border-line {
    border-bottom: 1px solid #dee2e6;
}

.mar0 {
    margin: 0px !important;
}

.showingResults p {
    margin-bottom: 4px;
    margin-top: 25px;
}

.showingResults div select {
    margin-bottom: 10px;
    margin-top: 10px;
}

.action-option {
    z-index: 100;
}

.sidepop_body .user-card {
    padding: 30px;
}

.downloadul {
    width: 240px;
}

.half-block-download .file-btn {
    width: 100%;
}

.navbar .navbar-nav.sub-header .nav-item {
    height: auto;
}

.navbar .navbar-nav.sub-header .nav-item.active button {
    border-bottom: 2px solid transparent;
}

.sub-header,
.sub-header .nav-link,
.sub-header .nav-link:focus,
.sub-header .nav-link:hover {
    border: 0px !important;
}

.navbar .navbar-nav.sub-header .nav-item.active button {
    border-bottom: 2px solid #454d66 !important;
}

.tele-class .react-tel-input input[type=text],
.react-tel-input input[type=tel] {
    width: 410px !important;
}

.pagination {
    margin: 0px 0px 10px 0px;
    padding: 20px;
}

/* 
.pagination .active {
    background: #454d66;
    border-radius: 50%;
    width: 42px;
    font-size: 14px;
}
.pagination li{
    padding: 10px;
    background: #454d66;
    border-radius: 50%;
    width: 42px;
    font-size: 14px;
    cursor: pointer;
   background: gray;
    text-align: center;
    margin: 0px 5px;
}
.pagination  .disabled{
    background: #454d6636;
    border-radius: 50%;
    cursor: not-allowed;
    width: 42px;
    font-size: 14px;
}
.pagination a {
   font-size: 16px;
   color: white;
} */

.pagination {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    background-color: #fff;
}

.pagination li.page-item {
    display: inline-block;
}

.pagination .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #454d66;
    background-color: #fff;
    margin: 0 4px;
    border-radius: 50%;
    border: 1px solid #dee2e6;
}

.pagination .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.pagination .page-item.active .page-link {
    background-color: #454d66;
    border: 0px;
    outline: none;
}

.pagination .page-item:last-child .page-link {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

span.file-tag {
    padding: 12px 24px;
    background-color: #f5f5f5;
    border-radius: 30px;
    position: relative;
    padding-right: 64px;
}

span.file-tag .img-close {
    position: absolute;
    background: #00000059;
    border-radius: 100%;
    top: 10px;
    right: 16px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    padding: 0px;
}

.previewdatawrapper {
    margin: 10px;
}

.entitytree .action-group {
    position: absolute;
    right: 0px;
    top: 30px;
    display: block;
}

.entitytree .treeview ul li:before {
    content: unset !important;
}

.entitytree .treeview ul li::after {
    content: unset !important;
}

/* .treeview ul li:after{
        content: unset !important;
    } */

.adminbg {
    background-color: #fff;
}

.adminbg th {
    border: 0px
}

.adminbg table td {
    padding: 1.4rem 1rem;
    color: #3d4b72;
}

.adminbg thead th {
    border: 0px !important;
    opacity: 0.5;
}

.adminbg thead th img {
    opacity: 1;
}

.adminbg table td {
    border-top: 0px;
}

.adminbg table tr {
    border-bottom: 1px solid #f1f1f3;
}

.adminbg {
    /* margin: 0 20px; */
    padding: 0px !important;
}

.adminlayout {
    margin-top: 100px;
    padding: 0px 20px
}

.adminlayout .card {
    margin: 0px !important;
}

.adminlayout .adminclientname {
    padding-left: 10px;
}

.adminbg .card-body {
    padding: 0px !important;
}

.adminbg table tr:last-child {
    border-bottom: 0px solid #f1f1f3;
}

.clienttab {
    padding: 0 30px;
    height: 56px;
    display: flex;
    width: 90px;
    outline: none;
}

.clienttab :focus,
.clienttab:active {
    outline: none;
}

.no-click-backdrop {
    pointer-events: none;
}

.identityDetails .sub-heading2 {
    color: #000
}

.identityDetails label {
    color: #9b98a1;
}

.switch input:checked+span p:before {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    transform: translate(-2px, 0);
}

.switch input+span p:before {
    content: "In-Active";
    transition: all .3s ease .2s;
}

.switch input+span p:before,
.switch input+span p:after {
    font-size: 14px;
    font-weight: 500;
    display: block;
    -webkit-backface-visibility: hidden;
}

.switch input+span p {
    font-weight: normal;
    position: relative;
    display: block;
    top: 1px;
}

.switch input:checked+span p:after {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: all .3s ease .2s;
}

.switch input+span p:after {
    content: "Active";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    color: #26b789;
    transition: all .3s ease;
    transform: translate(2px, 0);
}

#clientList {
    z-index: 10;
}

.switch_toggle .switch input+span:before {
    background: #e2a900;
}

.switch_toggle .switch input+span {
    color: #e2a900 !important;
}

.switch_toggle .switch input:checked+span:before {
    background: #26b789 !important;
}

.emppreview_ptag {
    display: inline-block;
    margin-left: 10px;
}

.padding0 {
    padding: 0px !important;
}

.cv.insufficiency {
    background-image: url(../images/icons/cv-insufficiency.svg);
}

.cv.in-progress,
.cv.yet-to-start {
    background-image: url(../images/icons/cv-in-progress.svg);
}

.cv.clear {
    background-image: url(../images/icons/cv-clear.svg);
}

.cv.suspicious {
    background-image: url(../images/icons/cv-suspicious.svg);
}

.cv.failed {
    background-image: url(../images/icons/cv-failed.svg);
}

.cv {
    background-image: url(../images/icons/cv.svg);
}

.right-continue {
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
}

@media(max-width: 1280px) {
    .login-left {
        width: 400px;
    }
}

@media(max-width: 1024px) {
    .login-left {
        width: 80%;
        margin: 0px auto;
        float: none;
    }

    .login-bg .login-block {
        margin: 0px auto;
        float: none;
        margin-top: 6%;
        margin-bottom: 40px;
    }

    .login-bg {
        padding-top: 40px;
    }
}

@media(max-width: 768px) {
    .login-bg .login-block {
        width: 80%;
        padding-bottom: 20px;
    }
}

.border-style-class {
    border: 2px solid red;
    background: red;
    color: red;
}

.padding15 {
    padding: 15px;
}

.tele-feild-class .react-tel-input input[type=tel] {
    width: 415px !important;
}

.file-doc svg {
    border-radius: 100%;
    padding: 7px;
    height: 35px;
    width: 35px;
}

.float-btn {
    position: absolute;
    bottom: -12px;
    right: 0px;
    height: 35px;
    width: 35px;
}

.float-btn .file-doc svg {
    height: 30px;
    width: 30px;
}

.proof-doc {
    position: relative;
    overflow: hidden;
    min-height: 100px;
}

.overlay-card {
    position: absolute;
    width: 100%;
    height: 0%;
    top: -100px;
    align-items: center;
    background: #6b6b6b75;
    transition: 0.4s all linear;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.proof-doc img {
    margin: 0px auto;
    max-height: 100%;
}

.overlay-card .btn {
    padding: 8px 0px;
}

.btngroup {
    grid-column-start: 2;
    grid-column-end: 3;
}

.proof-doc:hover .overlay-card {
    height: 100%;
    top: 0px;
}

.tot-cases {
    float: right;
}

.inlinedisplay {
    display: inline;
}

/* icons */

.court.failed {
    background-image: url(../images/icons/court-failed.svg) !important;
}

.credit.failed {
    background-image: url(../images/icons/credit-failed.svg) !important;
}

.database.failed {
    background-image: url(../images/icons/database-failed.svg) !important;
}

.drug.failed {
    background-image: url(../images/icons/drug-failed.svg) !important;
}

.education.failed {
    background-image: url(../images/icons/education-failed.svg) !important;
}

.employment.failed {
    background-image: url(../images/icons/employment-failed.svg) !important;
}

.id.failed {
    background-image: url(../images/icons/id-failed.svg) !important;
}

.reference.failed {
    background-image: url(../images/icons/reference-failed.svg) !important;
}

.residence.failed {
    background-image: url(../images/icons/residence-failed.svg) !important;
}

.social.failed {
    background-image: url(../images/icons/social-failed.svg) !important;
}

.cepending {
    font-weight: 500;
    /* margin-top:25px;
    margin-left:20px */
}

.cepending {
    color: #26b789 !important
}

.insufficiency {
    color: #f0d317 !important
}

/* insuf #fbe44f  suspe #ffc875 */

.suspicious {
    color: #ffa724 !important;
}

.tree-area .btn {
    padding: 9px 16px !important;
}

.btn_auto {
    padding: 9px 0px;
}

.clientName {
    max-width: 200px;
}

.search_insuff {
    display: inline;
    position: relative;
}

.insuff_search {
    position: absolute;
}

.search_insuff .search-filter {
    width: 250px;
    float: right;
}

.mtop20 {
    margin: 15px 0px 0px 0px;
}

.companysuspicious {
    display: inline-block;
    margin-left: 20px;
}

.right-extend-time .form-control {
    margin-top: 0px !important;
    width: 40px !important;
    margin-right: 10px;
}

.line-height-2 {
    line-height: 2;
}

.font-12 {
    font-size: 12px;
    color: #3d4b72;
}

.inflow-outflow {
    height: 300px;
}

.wip-chart {
    height: 450px;
}

.right-select {
    margin: 0px;
    justify-content: flex-end;
}

.cv-remark {
    width: 100%;
    padding: 5px 15px;
}

.search-filter .form-control.select-padding {
    padding-right: 30px;
}

.tat-graph {
    width: 130px;
    background-color: none !important;
    margin-bottom: 25px;
    display: inline-block;
    float: right;
}

.inline-tag {
    display: inline-block;
}

.tat-analysis-card .form-group,
.tat-analysis-card select {
    margin-bottom: 0 !important;
}

.docNameSmall {
    width: 200px;
}

.wordbreak {
    white-space: pre-wrap;
    word-break: break-word;
}

.text-wrap {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.pack-badge {
    width: 122px !important;
}

.pad20 {
    padding: 20px;
}

.packdetailstab thead th {
    border-bottom: 0px !important;
    border-color: #fff !important;
}

.packdetailstab td {
    border-bottom: 0px !important;
    border-color: #fff !important;
}

.bt-pack-approval {
    background-image: url(../images/icons/employment.svg);
    width: 14px;
    height: 16px
}

.bt-cost-approval {
    background-image: url(../images/icons/money.svg);
    width: 14px;
    height: 16px
}

.entity-area {
    padding: 25px !important;
}

.padr10 {
    padding-right: 10px;
}

.packdetails .span_sets span:after {
    content: ',';
    margin-right: 10px;
}

.packdetails .span_sets span:last-child:after {
    content: ' ';
}

.blank-field-list {
    font-size: 12px;
    font-weight: 400;
    color: orange;
}

/* .bgvform .edit-flow .input-empty, .bgvform .edit-flow .phone-input-empty .react-tel-input{
    border: 2px solid orange;
} */

.component-icons img {
    max-width: unset;
}

.admin-side-menu a .bt-icon.bt-pack-approval {
    margin-top: -3px;
    background-size: contain;
    height: 22px;
}

.info-modal .modal-body h6 {
    margin-bottom: 25px;
    margin-top: 25px;
}

.inline-bloc,
.inline-block {
    display: inline-block;
}

.packdetails div:last-child:after {
    content: '';
}

.tag-list .bubblerad {
    background: #eceef3 !important;
    border-radius: 20px !important;
    width: 180px;
}

.location-tag img {
    fill: #8798ad;
    float: left;
    height: 20px;
    margin-top: 5px;
    margin-right: 10px;
}

.case-status-badge {
    width: 122px;
}

.text-align-right {
    text-align: right;
}

.case-view-heading {
    display: inline-block;
}

.case-view-sub-heading {
    font-weight: 400;
    font-size: 15px;
}

.bgvform .accordion .card {
    overflow: visible;
}

.court.in-progress-match-found {
    background-image: url(../images/icons/court-in-progress-match-found.svg)
}

.court.court-exception {
    background-image: url(../images/icons/court-in-progress-match-found.svg)
}

.court.in-progress-foreign-address {
    background-image: url(../images/icons/court-in-progress-foreign-address.svg)
}

.orgcard .location svg {
    margin-top: 25px;
}

.orgview .mtop20 {
    margin-top: 35px;
}

.disabled_btn {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.5;
}

.z_index_high {
    z-index: 999;
}

.add-document-modal.org_multi_address .modal-body {
    padding-top: 0px;
}

.add-document-modal.org_multi_address>.modal-dialog {
    max-width: 90% !important;
}

.org_multi_address_modal .react-tel-input input[type=text],
.react-tel-input input[type=tel] {
    width: inherit !important;
}

.admin-side-menu a .bt-icon.bt-pack-approval {
    margin-bottom: 10px;
}

.clone_pack .form-group {
    margin-bottom: 0px !important;
}

.default-pack-badge {
    width: 75px !important;
}

.clone-wrap .clone-details {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    padding: 10px 10px;
    border-radius: 4px;
    position: absolute;
    top: 37px;
    width: 68px;
    background: #fff;
    z-index: 1;
    display: none;
}

.clone-wrap:hover .clone-details {
    display: block
}

.pack-info-icon-wrap .info-pack-details {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    padding: 10px 10px;
    border-radius: 4px;
    position: absolute;
    top: 37px;
    width: 115px;
    background: #fff;
    z-index: 1;
    display: none;
}

.pack-info-icon-wrap:hover .info-pack-details {
    display: block
}

.two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 50px;
}

.header-two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 46px;
}

.header-three-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 75px;
}

.bt-pack-orgmanagement {
    background-image: url(../images/icons/reference.svg);
    width: 14px;
    height: 16px
}

.admin-side-menu a .bt-icon.bt-pack-orgmanagement {
    margin-top: -3px;
    background-size: contain;
    height: 22px;
    margin-bottom: 10px;
}

.pad20 {
    padding: 10px;
}

.bg_code_ {
    color: #000;
    background: #eceef3 !important;
}

.bg_code_Yet {
    color: #000;
    background: #eceef3 !important;
}

.bg_code_Suspicious {
    color: #fff;
    background: #ffa724 !important
}

.bg_code_On {
    color: #000;
    background: #eceef3 !important;
}

.bg_code_Insufficiency {
    color: #000;
    background: #f0d317 !important;
}

.bg_code_In {
    color: #000;
    background: #eceef3 !important;
}

.bg_code_Failed {
    color: #fff;
    background: #fe6666 !important;
}

.bg_code_Clear {
    color: #fff;
    background: #26b789 !important;
}

.margin_top_10 {
    margin-top: 10rem;
    /* padding: 10px; */
}

.bgv_showDoc {
    position: absolute;
    margin-top: -30px;
    right: 20px;
    background: #000;
    padding: 0px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoomIn input[type=checkbox] {
    display: none;
}

.container_zoom img {
    /* margin: 100px; */
    transition: transform 0.25s ease;
    cursor: zoom-in;
}

.zoomIn input[type=checkbox]:checked~label>img {
    transform: scale(1.2);
    transition: transform .2s;
    cursor: zoom-out;
}

.break_style .seperate-block {
    width: 100%;
}

.break_style .seperate-block .col-sm-3 {
    display: inline-block;
}

.break_style .seperate-block .col-sm-6 {
    display: inline-block;
}

.break_style {
    padding: 10px;
}

.tourHeading {
    margin-bottom: 10px !important;
    text-align: center;
}

.nonEditText {
    font-size: 14px;
}

/* .button-glow {
        background-color: #1c87c9;
        border: none;
        color: #eeeeee;
        cursor: pointer;
        display: inline-block;
        padding: 10px 10px;
        text-align: center;
        text-decoration: none;
        }
        @keyframes glowing {
        0% { background-color: #454d66; box-shadow: 0 0 5px #454d66; }
        50% { background-color: #454d66; box-shadow: 0 0 20px #454d66; }
        100% { background-color: #454d66; box-shadow: 0 0 5px #454d66; }
        }
        .button-glow {
        animation: glowing 1300ms infinite;
        }
     */

.reportshare {
    position: absolute;
    right: 38px;
    top: 30px;
}

.modal_black_bg {
    background: #000000d6;
}

.cibil_status_Insufficient {
    color: #f0d317;
}

.cibil_status_Completed {
    color: #26b789;
}

.check-management {
    margin-top: 70px;
}

.straight_th th,
.straight_td {
    white-space: nowrap;
}

.sort_th {
    position: relative;
    cursor: pointer;
    user-select: none;
}

.sort_th:after {
    background-image: url(../images/icons/icon-sort.svg);
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: contain;
}

.sort_th.sort-ascending:after {
    background-image: url(../images/icons/sort-ascending.svg);
    background-position: 5px 3px;
}

.sort_th.sort-descending:after {
    background-image: url(../images/icons/sort-descending.svg);
    background-position: 5px 3px;
}

.case-tbl.straight_th .table th,
.case-tbl.straight_th .table td {
    font-size: 12px;
}

.case-tbl.straight_th .table img {
    max-width: 80%;
}

.straight_name {
    white-space: nowrap;
    width: 91px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.straight_address {
    white-space: nowrap;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.straight_vendor {
    white-space: nowrap;
    width: 180px;
    text-overflow: ellipsis;
}

.casecompletedCheck .col-sm-2 {
    padding: 10px 10px;
    height: 100px;
}

.casecompletemulti {
    width: 250px !important;
    float: right;
}

.inputDiv {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background: #f5f5f5;
    font-size: 13px;
    height: 40px;
    cursor: pointer;
    padding: 11px;
    margin-bottom: 20px;
}

.selectCheckBox {
    position: relative;
}

.selectCheckBox ul {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0px;
    background: #fff;
    z-index: 1;
    border: 1px solid #e7e7e7;
    max-height: 200px;
    overflow: auto;
}

.selectCheckBox ul li {
    padding: 10px;
    border-bottom: 1px solid #e7e7e7;
}

.selectCheckBox ul li:last-child {
    border-bottom: 0px;
}

.selectCheckBox ul li .search-filter {
    margin-bottom: 0rem;
}

.selectCheckBox ul li .checkclass .container_Checkbox.roundedcheckbox {
    margin-top: 0rem;
}

.inputDiv:before {
    content: '';
    position: absolute;
    background-image: url(../images/icons/icon-select-arrow.svg);
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    right: 15px;
    top: 10px;
    cursor: pointer;
}

.casecompletedCheck {
    justify-content: flex-end;
}

.case-tbl .table tbody td {
    font-size: 12px !important;
}

.ml_minus {
    margin-left: -25px;
}

.widthname {
    width: 200px;
}

.tableheaderfont th {
    font-size: 12px;
    font-weight: 500 !important;
    color: #2E384D !important;
}

label.note {
    font-size: 11px;
    margin-top: 6px;
    margin-bottom: 15px;
}

.formgroupmargin .form-group {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.align-self-center {
    align-self: center;
}

.newcompletetable .case-tbl .table tbody td {
    cursor: default !important;
}

.cases-set .left-form-elements .form-group {
    width: 11%;
    margin-right: 15px;
}

.cases-set .operations.left-form-elements .form-group {
    width: 40%;
    margin-right: 15px;
}

.reportpreviewcard {
    background: #fff;
    border-radius: 4px;
    padding: 10px 5px;
    margin: 0px 10px 20px 0px;
}

.bt-vendor-assignment {
    background-image: url(../images/icons/icon-profile-settings.svg);
    width: 24px;
    height: 24px
}

.css-26l3qy-menu {
    z-index: 99999;
}

.bt-vendor-details {
    background-image: url(../images/icons/cv.svg);
    width: 24px;
    height: 24px
}

.vendorPadBottom {
    padding-bottom: 200px;
}

.bg_completed {
    background-color: #26b789 !important;
    color: #fff;
}

.bg_failed {
    background-color: #fe6666 !important;
    color: #fff
}

.border_error input {
    border: 1px solid red !important;
}

.form-group label {
    word-break: keep-all;
}

.search_box_width {
    width: 220px;
}

.sub_header_3 {
    color: #454d66 !important;
    font-size: 17px;
}

.full_width_container {
    width: 100%;
}

.react_select_remove_hidden .select__multi-value__remove {
    display: none !important;
}

.react_select_remove_hidden .select__indicators .select__clear-indicator {
    display: none !important;
}

.react_select_removeall_hide .select__indicators .select__clear-indicator {
    display: none !important;
}

.remove_vendor {
    width: 18px;
    background-color: #e4e4e4;
    border-radius: 50%;
    padding: 3px;
    margin: 1px 0px 2px 10px;
}

.pack_info_dashboard {
    margin-left: 12px;
    position: relative;
}

.pack_info_dashboard .info-pack-details {
    top: 25px !important;
    right: -100px !important;
}

.casemanagement_badge .case-status-badge {
    width: unset !important;
}

.notification-list .notification-content .text-action span {
    color: #26b789;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
}

.admin-side-menu .adminstration_panel.active,
.admin-side-menu .adminstration_panel:hover {
    background-color: #fff;
}

.admin-side-menu .adminstration_panel {
    padding: 12px 12px !important;
    margin-bottom: 8px;
}

.admin-side-menu .adminstration_panel {
    color: #454d66;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    display: block;
    cursor: pointer;
    padding: 10px 15px;
    position: relative;
}

.admin-side-menu .adminstration_panel .bt-icon {
    width: 24px;
    float: left;
    margin-top: -3px;
    margin-right: 10px;
}

.highLightCard {
    /* background: #f5f5f5; */
    border-radius: 10px;
    padding-top: 10px;
    box-shadow: 0px 0px 20px 0px #21212121
}

.bt-user-icon {
    background-image: url(../images/icons/icon-user.svg);
    width: 24px;
    height: 24px
}

.residence-map-table .priority-suspicious {
    color: #ffa724 !important;
    opacity: 1;
}

.dot-option li:hover {
    background: #f5f5f5 !important
}

.residence-map-table th {
    font-size: 14px !important;
    opacity: 0.4;
    cursor: default;
}

.residence-map-table .case-tbl .table tbody td {
    font-size: 12px !important;
    cursor: default;
}

.residence-map-wrapper .col-6 .commontbl {
    height: 600px;
    overflow: auto;
}

.ck-editor__editable {
    height: 160px !important;
}

/* .ck-editor__top , .ck-editor__editable{
    max-width: 850px !important;
} */

.ck.ck-editor__main>.ck-editor__editable {
    background-color: #f5f5f5;
}

.straight_td .danger {
    color: red
}

.casereporttable thead th {
    border-top: 0px !important;
}

.casereporttable table,
.casereporttable th,
.casereporttable tr,
.casereporttable td {
    cursor: default;
}

.dribbledotrotate .bt-dribble-bot-v {
    width: 35px !important;
    transform: rotate(90deg) !important;
}

.reassignmodal .modal-dialog {
    width: 650px !important;
    top: -230px !important;
}

/* .reassignmodal .modal-dialog-centered{
    display: block;
}
.reassignmodal .modal-dialog-centered{
    /* align-items: */

.multiemail-style {
    width: 500px;
    border: 1px solid #eee;
    background: #f3f3f3;
    padding: 25px;
    margin: 20px;
}

.span.file-tag {
    margin-right: 3px;
}

.userinfo-caseinfo-toggle .toggle-label-new {
    margin-top: 5px !important;
    margin-bottom: 20px !important;
    width: 470px !important;
}

/* india map css */

.map-default {
    stroke: none;
}

.map-hover {
    stroke: #52f01380;
    stroke-width: 2;
}

.MapExplorer {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 30rem;
}

.MapExplorer .header,
.map_explorer_wrapper .header {
    display: flex;
    flex-direction: column;
    color: #343a40;
    margin-bottom: 1rem;
    align-self: flex-start;
}

.MapExplorer .svg-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.MapExplorer .svg-parent svg {
    align-self: center;
}

.MapExplorer .svg-parent svg text {
    text-transform: uppercase;
    text-align: right;
    font-weight: 600;
    fill: #6c757d;
    font-size: 18px;
}

.MapExplorer .back-button,
.map_explorer_wrapper .back-button {
    position: absolute;
    cursor: pointer;
    background: #b5b2b224;
    padding: 8px 15px;
    border-radius: 6px;
    left: 0;
    height: 40px;
    color: #00000094;
    font-size: 14px;
}

.MapExplorer .meta,
.map_explorer_wrapper .meta {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: 1rem;
}

.MapExplorer .meta h2,
.map_explorer_wrapper .meta h2 {
    margin: 0;
    color: #e23028;
    word-wrap: break-word;
    font-weight: 900;
}

.MapExplorer .meta h4,
.map_explorer_wrapper .meta h4 {
    margin: 0;
    color: #6c757d 99;
    word-wrap: break-word;
}

.MapExplorer .meta .unknown,
.map_explorer_wrapper .meta .unknown {
    position: absolute;
    bottom: -4.5rem;
    width: 5rem;
}

.MapExplorer .map-stats {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    position: relative;
}

.MapExplorer .map-stats h1,
.MapExplorer .map-stats h5,
.MapExplorer .map-stats h6,
.MapExplorer .map-stats h3 {
    margin: 0;
}

.MapExplorer .map-stats h3 {
    color: #6c757d 99;
    font-weight: 900;
}

.MapExplorer .map-stats .stats {
    background: #ff073a 20;
    color: #ff073a;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.MapExplorer .map-stats .stats .stats-bottom {
    display: flex;
    flex-direction: row;
}

.MapExplorer .map-stats .stats .stats-bottom h6 {
    margin-top: auto;
    margin-bottom: 0.25rem;
}

.MapExplorer .map-stats .stats h1 {
    font-weight: 600;
}

.MapExplorer .map-stats .stats.is-green {
    background: #28a745 20;
}

.MapExplorer .map-stats .stats.is-green h5,
.MapExplorer .map-stats .stats.is-green h6 {
    color: #28a745 99;
}

.MapExplorer .map-stats .stats.is-green h1 {
    color: #28a745;
}

.MapExplorer .map-stats .stats.is-blue {
    background: #007bff 10;
}

.MapExplorer .map-stats .stats.is-blue h5,
.MapExplorer .map-stats .stats.is-blue h6 {
    color: #007bff 99;
}

.MapExplorer .map-stats .stats.is-blue h1 {
    color: #007bff;
}

.MapExplorer .map-stats .stats.is-gray {
    background: #6c757d 10;
}

.MapExplorer .map-stats .stats.is-gray h5,
.MapExplorer .map-stats .stats.is-gray h6 {
    color: #6c757d 99;
}

.MapExplorer .map-stats .stats.is-gray h1 {
    color: #6c757d;
}

.MapExplorer .map-stats .stats.is-yellow {
    background: #ffc107;
}

.MapExplorer .map-stats .stats.is-yellow h5,
.MapExplorer .map-stats .stats.is-yellow h6 {
    color: #fff;
}

.MapExplorer .map-stats .stats.is-yellow h1 {
    color: #fff;
}

.MapExplorer .map-stats .stats:first-child {
    margin-left: 0;
}

.MapExplorer .map-stats .stats:last-child {
    margin-right: 0;
}

@media (max-width: 769px) {

    .TimeSeries,
    table,
    .header,
    .Level,
    .timeseries-header,
    .TimeSeries-Parent,
    .Links,
    .Minigraph,
    .Summary,
    .FAQ,
    .MapExplorer {
        width: calc(100% - 2rem);
    }

    .Home {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .Home .home-left,
    .Home .home-right {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

/* india map css end */

.residence-map-wrapper .padding-card {
    width: 100%;
}

.residence-map-wrapper .top-card-set {
    padding: 20px 0px 0px 0px !important;
}

.india-map-wrapper {
    height: 100%;
}

.MapExplorer .casetype {
    width: 150px;
    position: absolute;
    right: 0;
}

.MapExplorer .form-control {
    border-color: #e2e2e200;
    border-radius: 6px;
    background: #f5f5f5;
    font-size: 13px;
    height: 40px;
    color: #00000096;
}

.residence-map-wrapper .col-12 .commontbl {
    max-height: 500px;
    overflow: auto;
}

.table_head_opacity th {
    opacity: 0.6;
}

.casecompleteddetails .cases-set {
    padding: 20px 0px !important;
}

.casecompleteddetails .casecompletedCheck {
    width: 100%;
    margin: 0px !important;
}

.casecompletedlayout .top-card-set {
    padding-top: 20px !important;
}

.bt-residence-icon {
    background-image: url(../images/icons/residence.svg);
    width: 14px;
    height: 18px
}

.bt-pendingemp {
    background-image: url(../images/icons/pending.svg);
    width: 14px;
    height: 18px
}

.case-management-report-share {
    display: inline-block;
    float: right;
    margin-right: 30px;
    margin-top: 3px;
}

.case-type-switch .switch input+span strong:before {
    content: "Beyond Tat  Cases";
}

.case-type-switch input+span strong:after {
    content: "Showing All Cases" !important;
}

.case-type-switch .switch input+span:before {
    top: 2px;
    left: 0;
    width: 42px;
    height: 21px;
}

.case-type-switch .switch input+span:after {
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
}

.residencevendor .straight_td {
    width: 230px !important;
}

.residencevendor .straight_td .straight_name {
    width: 170px !important;
}

.table_header_light {
    font-size: 14px !important;
    opacity: 0.4;
    cursor: default;
}

.cursor-default {
    cursor: default !important;
}

.cursor-default td {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.badge-td {
    padding-left: 0px !important;
}

/* .undoableModal .modal-dialog{
           width: 670px !important;
       }
       @media (min-width: 576px){
.undoableModal .modal-dialog {
    max-width: 500px;
    margin: -100px auto !important;
}
} */

.undoableModal {
    padding-bottom: 0px !important;
}

.view_primary_btn {
    padding: 10px 27px;
    background: #454D6F;
    border-radius: 20px;
    cursor: pointer;
}

.case-inner-row {
    display: none;
}

.case-inner-row .table {
    margin: 0px;
}

.case-inner-row .table:first-child td {
    border-top: 0px;
}

.case-inner-row .table>td {
    padding: 0px !important;
}

.case-inner-row .table td {
    width: 80px;
}

.case-inner-row .table .first_td {
    width: 130px;
}

.completed-case-row {
    transition: all 0.2s linear;
}

.completed-case-row .accordion-arrow {
    transition: all 0.2s linear;
    transform: rotate(180deg);
}

.completed-case-row.completed-case-row-active+.case-inner-row {
    display: table-row;
}

.completed-case-row.completed-case-row-active .accordion-arrow {
    transform: rotate(0deg);
}

.completed-case-row td {
    width: 80px;
}

.completed-case-row .first_td {
    width: 130px;
}

.report-shared-switch .switch input+span strong:before {
    content: "No";
}

.report-shared-switch input+span strong:after {
    content: "Yes";
}

.sml-switch .switch input+span:before {
    top: 2px;
    left: 0;
    width: 42px;
    height: 21px;
}

.sml-switch .switch input+span:after {
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
}

.cell_hightlight {
    background-color: #d4d1d1e6 !important;
    border: 1px solid #ececec !important;
}

.card_highlight {
    background-color: #fff !important;
    /* box-shadow: 4px 4px 11px rgba(0,0,0,0.1); */
}

.overall_count .white-bg {
    background-color: #d6d6d64f;
}

.overall_count .white-bg:hover {
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.1);
}

.form-group-wrapper .form-group {
    margin-bottom: 10px !important;
}

.dialog-modal-sml .modal-dialog {
    width: 600px !important;
}

.dialog-modal-sml .modal-body {
    padding: 10px !important;
}

.padl0 {
    padding-left: 0px !important;
}

.pointer-td td {
    cursor: pointer;
}

.report_share_toggle .reportshare {
    margin-top: 0px !important;
}

.summary_row_color_1 {
    background-color: #ececec;
}

.summary_row_color_2 {
    background-color: #fff;
}

.summary_row_color_1 td:first-child {
    background-color: #fff !important
}

.summary_row_color_2 td:first-child {
    background-color: #fff !important
}

.pivot_style_wrapper {
    cursor: pointer;
}

.pivot_style_wrapper td {
    padding: 15px 12px !important
}

.pivot_style_wrapper .case-inner-row td:first-child {
    padding: 0px !important;
}

.pivot_style_wrapper .case-inner-row td {
    height: 53px !important;
}

.pointer_events_none {
    pointer-events: none;
}

/* .pointer_events_auto{
    pointer-events: auto !important ;
} */

.summary_inner_color {
    background-color: #e8e8e84f
}

.summary_inner_color .imagetd {
    background-color: #fff !important;
}

/* .toggle_down_arrow:hover{
    background-color: #6c757d20;
}
.completed-case-row td:first-child:hover{
    background-color: #6c757d20;
} */

.user_info_height {
    height: 55vh;
    overflow: auto;
}

.hide_value {
    display: none;
}

.mb50 {
    margin-bottom: 50px;
}

.hover_header {
    position: relative;
}

.hover_data {
    display: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    padding: 6px 10px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 23px;
    width: 138px;
    background: #fff;
    z-index: 1;
}

.sml_confirmation_modal {
    padding: 30px 25px !important;
}

.verificationstatus .grey {
    color: #a4a1a1;
}

.verificationstatus .blue {
    color: #3d4b72;
}

.verificationstatus .green {
    color: #2e744c;
}

/* green,amber,red,orange,blue,grey */

.verificationstatus .orange {
    color: #ffa724;
}

.verificationstatus .red {
    color: #E03B24;
}

.verificationstatus .yellow {
    color: #f9d800;
}

.verification_wrapper {
    margin-top: 80px;
    /* padding: 20px; */
}

.verification_container .pin_location {
    position: absolute;
    top: 25px;
    opacity: 0.4;
    height: 35px;
}

.verification_container .upload-div {
    position: relative;
    height: 130px;
}

.verification_container .upload-div div {
    position: absolute;
    bottom: 25px;
    opacity: 0.6;
    font-size: 14px;
    color: #000;
}

.verification_container .upload-div .sub_text {
    position: absolute;
    bottom: 8px;
    opacity: 0.6;
    font-size: 12px;
    color: #000;
}

.verification_container {
    margin-bottom: 40px;
    padding: 15px;
}

@media (max-width: 768px) {
    .submit_btn .btn {
        width: 65%;
        margin: 0 20 20px 20 !important;
    }
}

.submit_btn {
    text-align: center;
}

.verification_container .img_wrap {
    background-color: #80808024;
    position: relative;
    text-align: center;
}

.verification_container .remove_vendor {
    position: absolute;
    right: -9px;
    top: -9px;
    width: 23px;
    border: 0.5px solid #80808014;
    /* background: #606060e8;
    border-radius: 50%; */
}

/* .verification_container .row{
    margin-left: 15px;
    margin-right: 15px;
} */

/*****Openvidu_css**************/

.openvidu_wrap video {
    width: 100%;
    max-height: 300px;
    float: left;
    cursor: pointer;
}

.openvidu_wrap .streamcomponent div {
    position: absolute;
    background: #f8f8f8;
    padding-left: 5px;
    padding-right: 5px;
    color: #777777;
    font-weight: bold;
    border-bottom-right-radius: 4px;
}

.openvidu_wrap .stream-container {
    padding: 0;
}

.openvidu_wrap .vertical-center {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.openvidu_wrap .horizontal-center {
    margin: 0 auto;
}

.openvidu_wrap .form-control {
    color: #0088aa;
    font-weight: bold;
}

.openvidu_wrap .form-control:focus {
    border-color: #0088aa;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 170, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 170, 0.6);
}

.openvidu_wrap input.btn {
    font-weight: bold;
}

.openvidu_wrap .btn {
    font-weight: bold !important;
}

.openvidu_wrap .btn-success {
    background-color: #06d362 !important;
    border-color: #06d362;
}

.openvidu_wrap .btn-danger {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.openvidu_wrap .btn-success:hover {
    background-color: #1abd61 !important;
    border-color: #1abd61;
}

/*video {
      width: 100%;
      height: auto;
 }*/

.video_component {
    width: 100%;
}

/* xs ans md screen resolutions*/

@media screen and (max-width: 991px) {
    .openvidu_wrap .vertical-center {
        width: 85%;
        padding-top: 10px;
        top: 57%;
    }
}

/******Openvidu****/

.sub-heading4 {
    color: gray;
    font-weight: 500;
    opacity: 0.7;
}

.btn_danger {
    background-color: #d9534f;
    color: #fff;
}

.btn_danger:hover {
    color: #fff !important;
}

.pad30 {
    padding: 30px;
}

@media(max-width: 760px) {
    .btn_danger {
        width: 50%;
    }

    .main_video video {
        margin-bottom: 20px;
    }
}

@media(min-width: 960px) {
    .main_video video {
        margin-bottom: 20px;
    }

    .submit_btn .btn_primary {
        margin-left: 10px;
    }

    .landmark_wrap .img_wrap,
    .exterior_wrap .img_wrap {
        height: 150px;
    }

    .landmark_wrap .img_wrap .uploaded_img,
    .exterior_wrap .img_wrap .uploaded_img {
        height: 100%;
    }
}

@media (max-width: 768px) {
    /* .exterior_wrap , .landmark_wrap:first-child{
        margin-bottom: 15px;
    } */
}

.hide_btn {
    font-size: 12px;
    padding: 8px;
    font-weight: unset;
}

.end_session_btn .btn_primary {
    width: 50%;
}

@media (min-width: 768px) {
    .onvoicecall_img {
        width: 320px;
    }
}

.add-document-modal.reset-popup .modal-body {
    padding-top: 0px;
}

.add-document-modal.reset-popup>.modal-dialog {
    width: 35%;
    top: -45px
}

.pt0 {
    padding-top: 0px;
}

.details_wrapper {
    padding: 20px;
}

.mapbtn {
    margin-top: 27px;
}

.details_wrapper .card-set {
    padding: 30px;
    border-radius: 8px;
}

.logo_upload_wrapper {
    display: flex;
    width: 100%;
    height: 40px;
    background: #f8f8f8;
    border: 2px dashed #d5d5d5;
    align-items: center;
    /* justify-content: center; */
    padding-left: 10px;
    color: #3d4b72;
    cursor: pointer;
    position: relative;
}

.logo_upload_wrapper input[type=file] {
    position: absolute;
    height: 100%;
    width: 100%;
    outline: none !important;
    opacity: 0;
    cursor: pointer;
}

.check_text {
    margin-top: 35px;
    margin-left: 10px;
}

.loaWrapper .ck-editor__editable {
    height: 300px !important;
}

.client_admin_card .card-list {
    width: 100%;
    border: 1px solid #f1f1f3;
    margin-bottom: 30px;
}

/* .client_admin_card .user-card{
    border-radius: 7px;
    background-color: #eaeaeab8;
}  */

/* 
.client_admin_card .pack-group{
    width: 33%;
} */

.client_admin_card {
    padding: 30px;
    border-radius: 8px;
    margin: 0px;
}

.form_check {
    margin-top: 35px;
    margin-left: 7px;
    margin-bottom: 0px;
}

.progress_wrapper {
    margin-top: 100px;
}

.showHoverDetails:hover .hover_header {
    display: inline-block !important;
}

.hover_header {
    display: none;
}

.hover_header:hover .hover_data {
    display: block;
}

.case-tbl .table thead th {
    padding-bottom: 24px !important;
}

.text_weight_700 {
    font-weight: 700;
}

.disable_check {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.priority_num {
    margin-right: 20px;
    color: #020202 !important;
    opacity: 0.5;
}

.check_management_btn .btn {
    min-width: 120px !important;
}

.pack_optional_info .info-pack-details {
    top: 155px !important;
    left: 210px;
    width: 252px !important;
    background-color: #eaeaea;
}

.pack_optional_info .sub-heading4 {
    color: #000;
}

.inline-block {
    display: inline-block;
}

.MapExplorer svg {
    width: inherit;
}

.case-row .disable_report_download {
    cursor: not-allowed !important;
    opacity: .8;
    color: #808080de;
}

.sidepop_footer .disable_report_download {
    background-color: #7d7d7dad !important;
    color: #00000073;
    opacity: .8;
    cursor: not-allowed !important;
}

.li_progress {
    display: flex;
    flex: 0 0 20%;
    max-width: 26.666667%;
    position: relative;
    align-items: center;
    color: #C5C5C5;
}

.light-right-border {
    border-right: 1px solid #8080802b;
}

.li_progress .litext {
    height: 27px;
    width: 28px;
    border: 1px solid #C5C5C5;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 10px;
    color: #C5C5C5;
    cursor: pointer;
}

.li_progress.active .litext {
    border: 1px solid #2E384D;
    background: #2E384D;
    color: #fff;
}

.li_progress.active .linename {
    color: #2E384D;
}

.li_progress-border {
    border-bottom: 1px solid #C5C5C5;
    flex-grow: 1;
    margin: 0px 10px;
}

.text-content {
    display: flex;
    align-items: center;
}

.linearprogressbar .li_progress:last-child .li_progress-border {
    display: none;
}

.linearprogressbar {
    margin-right: 0px;
    margin-left: 0px;
    padding: 25px 35px 15px 35px;
}

.pr0 {
    padding-right: 0px;
}

.progress_nav_header {
    background-color: #fff;
    position: sticky;
    z-index: 99;
    width: 100%;
}

.right-content {
    padding-top: 0px;
}

.profile_img_wrapper .right {
    float: none !important;
}

.bt-snackbar-wrapper {
    z-index: 9999;
}

.full_width {
    width: 100%;
}

.downloadxls {
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
    margin-right: 0px;
    float: right;
}

.downloadxls .icon-details {
    left: -120px;
    top: 38px;
    width: 148px;
}

.unidentified-btn {
    border-radius: 20px;
    padding: 6px 24px;
    position: relative;
    width: 0px;
    margin-top: 0px;
    float: none;
}

.city-info {
    display: inline-block;
    margin-left: 7px;
    margin-right: 20px;
    float: none;
}

.preview_card {
    background: #d6d6d612;
    border: 1px solid #8080804a;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
}

.preview_card.active {
    background-color: #fff;
    box-shadow: 0px 0px 20px 0.5px #00000030;
}

.employment_wrap .user-card {
    border: 1px solid #D5D5D5;
    border-radius: 5px;
}

.employment_wrap .bt__img-text-group {
    display: flex;
    justify-content: center;
}

.employment_wrap .user-card {
    min-height: 180px;
    width: 180px;
}

.employment_wrap .user-card.inactive {
    opacity: 0.4;
}

.inactive .sub-heading1,
.inactive {
    color: gray;
}

.ml30 {
    margin-left: 30px;
}

.emp_break_wrap {
    padding: 15px;
    height: 180px;
    border: 1px solid #afafaf54;
    border-radius: 4px;
    margin: 0px 15px;
    margin-bottom: 20px;
}

.single_doc_upload .logo_upload_wrapper {
    height: 30px;
    width: 30px;
    padding: 0px;
    justify-content: center;
}

.single_doc_upload .logo_upload_wrapper input[type=file] {
    width: inherit;
}

.single_doc_upload {
    cursor: pointer;
}

.doc_file_container .proof-doc {
    text-align: center;
    margin-bottom: 15px;
    min-height: auto;
}

.doc_file_container .upload-file {
    height: 120px;
}

.mar5 {
    margin: 5px;
}

.overlay_icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
}

.doc_file_container .proof-doc:hover .overlay-card {
    position: absolute;
    left: 1px;
}

.check_info_header {
    background-color: #e4e4e4;
    border-radius: 4px;
    padding: 10px;
}

.progress_nav_header .li_progress .error,
.li_progress .error .linename {
    color: #dc8f8c !important;
}

.li_progress .text-content.error .litext {
    border: 1px solid #dc8f8c !important;
    color: #dc8f8c !important;
    background-color: #fff;
}

.progress_nav_header .li_progress.active .error,
.li_progress.active .error .linename {
    color: #d9534f !important;
}

.li_progress.active .text-content.error .litext {
    border: 1px solid #d9534f !important;
    color: #d9534f !important;
    background-color: #fff;
}

.bgv_case_edit.case-card-fill {
    margin-top: 4rem;
}

.bgv_case_edit .right-content {
    margin: 0px;
}

.bgv_case_edit .single_case_creation {
    margin-top: 5rem;
}

.side_info_wrap img {
    float: left;
}

.side_info_wrap div {
    margin-left: 25px !important;
}

.side_info_wrap {
    margin-top: 10px;
}

.card_icon_hover.icon-details {
    left: -50px;
    top: 20px;
    width: 82px;
}

.cursor_default {
    cursor: default;
}

.logo_upload_wrapper input[type=file] {
    cursor: pointer;
}

.file-wrapper {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.file-wrapper input {
    cursor: pointer;
    /* font-size: 100px; */
    filter: alpha(opacity=1);
    -moz-opacity: 0.01;
    opacity: 0.01;
    position: absolute;
    right: 0;
    top: 0;
}

.file-wrapper .button {
    /* background: #3d4b72; */
    /* -moz-border-radius: 5px;
    -webkit-border-radius: 5px; */
    /* border-radius: 5px; */
    color: #3d4b72;
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    margin-right: 5px;
    padding: 6px 13px;
    text-transform: uppercase;
}

.single_doc_upload .file-wrapper .button {
    border: 2px dashed #d5d5d5;
}

.f_12 {
    font-size: 12px;
}

.bold_text {
    font-weight: bold;
    color: #309975;
}

.case_view_wrap .bt-dribble-bot-v {
    height: 20px;
}

.case_view_wrap .action-option {
    min-width: 230px !important;
}

.remove_clr .clr15 {
    height: 0px !important;
}

.remove_clr h6 {
    margin-bottom: 15px !important;
}

.remove_clr .yes-or-no-modal .modal-body {
    padding: 20px 45px;
}

.emp_break_modal_wrap .sub-heading2 {
    display: inline-block;
    float: left;
}

.emp_break_modal_wrap div.clr5 {
    display: none;
}

.emp_break_modal_wrap .clr {
    height: 10px;
}

.vendor_assignment_wrap .navbar {
    height: unset;
}

.court_multi .css-yk16xz-control,
.multi-select-height .css-yk16xz-control {
    max-height: unset !important;
}

.court_multi .select__value-container {
    min-height: 40px !important;
}

@media(max-width: 1280px) {
    .linearprogressbar {
        padding: 25px 15px 15px 30px;
    }

    .li_progress {
        max-width: 19.666667%;
    }
}

.img-add {
    position: absolute;
    background: #00000059;
    border-radius: 100%;
    top: -2px;
    left: 94px;
    height: 24px;
    cursor: pointer;
    padding: 0px;
}

.bulkInitiateMailerButton {
    float: right;
    padding: 11px;
}

.form-group.inline-fields {
    display: flex;
}

.form-group.inline-fields .body2 {
    width: 80px;
}

.form-group.inline-fields.ccmailer .body2 {
    width: 40px !important;
}

.form-group.inline-fields .form-control {
    margin-top: -11px;
}

.form-group.inline-fields .logo_upload_wrapper {
    margin-top: -8px;
    width: 10%;
    height: 35px;
}

.bulkmailer.commontbl.exceltbl {
    height: 500px;
    overflow-y: scroll;
}

.unidentified-btn {
    border-radius: 20px;
    padding: 6px 24px;
    position: relative;
}

.city-info {
    display: inline-block;
    margin-left: 7px;
    margin-right: 20px;
    float: none;
}

.mailer .icon-details {
    top: 50px !important;
}

.mailer.title-hover {
    float: none;
}

.upload-div.local-file {
    background: #ffffff;
    border: 1px solid #bfbfbf;
    overflow-y: scroll;
}

.upload-div.local-file .file-tag {
    padding-right: 25px
}

.upload-div.local-file .row {
    margin-left: 20px;
    width: 92%
}

.local-file.col-5 {
    margin-bottom: 35px !important;
}

.bt-document-details {
    background-image: url(../images/icons/cv.svg);
    width: 24px;
    height: 24px
}

.changeFont {
    font-family: 'Poppins', sans-serif;
}

.download_zip {
    background-color: #f0f0f0;
    width: 160px;
}

.pdl15 {
    padding-left: 15px;
}

.newcompletetable .case-tbl .table tbody .doc_details td.pointer {
    cursor: pointer !important;
}

.doc_details_wrap .pivot_style_wrapper thead tr:first-child {
    cursor: default !important;
}

.doc_details .completed-case-row {
    cursor: pointer !important;
}

.doc_details_wrap .tableheaderfont th {
    color: #8f949f !important;
}

.donwload_all_zip {
    display: inline;
    float: right;
}

.donwload_all_zip .file-btn {
    background-color: #f0f0f0;
}

.doc_details_wrap .completed-case-row td {
    width: unset;
}

.info-modal .body2 {
    word-break: break-word;
}

.donwload_all_zip span {
    font-size: 13px;
    color: #3d4b72;
}

/* body{
    font-family: 'Poppins', sans-serif !important; 	
} */

.bgv_new_container .left-content {
    margin-top: 45px;
}

.bgv_new_container .left-content .sub-heading2 {
    font-size: 14px;
}

.btn_secondary_light.btn {
    padding: 0px 11px;
    font-size: 12px;
    height: unset;
    min-width: 70px;
}

.plus--icon {
    border: 1px dashed #AFAFAF;
    border-radius: 2px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.required-col {
    display: flex;
    justify-content: space-between;
}

.required-docs .row {
    margin: 10px 0px
}

.required-top {
    display: flex;
    align-items: center;
}

.required-top .container_Checkbox.roundedcheckbox {
    top: -11px;
}

.btn-group-img {
    background: #f0f0f0;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin: auto;
    display: flex;
}

.btn-group-img img {
    max-width: 70%;
    cursor: pointer;
}

.btn-group-img:hover {
    background: #fff;
}

.required-docs {
    margin: 15px 0px;
    padding-top: 10px;
}

.required-bottom .file-wrapper:hover {
    box-shadow: 2px 0px 11px rgba(0, 0, 0, 0.14)
}

.bgv_new_container .form-group input,
/* .bgv_new_container .form-group select , */

.bgv_new_container .form-group textarea {
    border: 1px solid #adadad17;
    background: #f5f5f5;
    resize: none;
}

.bgv_new_container .form-group input:focus,
.bgv_new_container .form-group select:focus,
.bgv_new_container .form-group textarea:focus {
    border: 1px solid #ced4da;
    background: #FBFBFB;
    border-radius: 4px !important;
}

.bgv_new_container .react-tel-input input[type=tel],
.emp_modal .react-tel-input input[type=tel] {
    background: #f5f5f5 !important;
}

.bgv_new_container .react-tel-input .flag-dropdown .emp_modal .react-tel-input .flag-dropdown {
    border: 1px solid #cacaca2e;
}

.mt15 {
    margin-top: 15px;
}

.add_ins_modal .form-group input,
.add_ins_modal .form-group select {
    border: none;
    background: #f5f5f5;
}

.add_ins_modal .form-group input:focus,
.add_ins_modal .form-group select:focus {
    border: 1px solid #ced4da;
    background: #FBFBFB;
}

.bgv_new_container .css-yk16xz-control,
.emp_modal .css-yk16xz-control,
.add_ins_modal .css-yk16xz-control {
    border: none;
    box-shadow: none;
    background: #f5f5f5;
}

.bgv_new_container .css-1pahdxg-control:hover,
.emp_modal .css-1pahdxg-control:hover,
.add_ins_modal .css-1pahdxg-control:hover {
    border: 1px solid #ced4da !important;
    background: #FBFBFB !important;
}

.bgv_new_container .css-1pahdxg-control:focus-within,
.emp_modal .css-1pahdxg-control:focus-within .add_ins_modal .css-1pahdxg-control:focus-within {
    border: 1px solid #ced4da;
    background: #FBFBFB;
}

.bgv_new_container .css-1pahdxg-control:active,
.emp_modal .css-1pahdxg-control:active,
.add_ins_modal .css-1pahdxg-control:active {
    border: 1px solid #ced4da;
    background: #FBFBFB;
}

.bgv_new_container .css-1pahdxg-control,
.emp_modal .css-1pahdxg-control,
.add_ins_modal .css-1pahdxg-control {
    background: none;
    border: none;
    box-shadow: none;
}

@media(max-width: 1260px) {
    .bgv_new_container .left-content {
        padding: 24px;
        margin-top: 3rem;
    }

    .li_progress .litext {
        font-size: 12px;
        height: 19px;
        width: 19px;
    }
}

.profile_img_wrapper .btn_secondary_light.btn {
    margin-left: 120px;
}

/* .btn_primary.btn:hover{
    color: #454d66 !important;
    background-color: #fff !important;
} */

.btn_secondary_light.btn:hover {
    color: #fff !important;
    background-color: #454d66 !important;
}

.btn_secondary.btn:hover {
    color: #fff !important;
    background-color: #454d66 !important;
}

.bgv_new_container .form-group label {
    color: #2E384D;
}

.bgv_new_container .flag-dropdown,
.emp_modal .flag-dropdown {
    margin: 1px;
}

.li_progress .title-hover {
    margin: 0px;
}

.linearprogressbar .li_progress:hover {
    color: #000;
}

.linearprogressbar .li_progress:hover .litext {
    color: #000;
    border-color: #2E384D;
}

.linearprogressbar .li_progress.active:hover .litext {
    color: #fff;
}

/* .linearprogressbar .li_progress:hover + .li_progress .litext {
    border: 1px solid #000;
    color: #000;
} */

.required-docs-wrap {
    background: #F5F5F5;
}

.nav_hover_wrap .icon-details {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.11);
}

.nav_hover_wrap .sub-heading1 {
    font-size: 13px;
    font-weight: 400;
}

.nav_hover_wrap .icon-details {
    top: 31px;
    width: unset;
    min-width: 200px;
}

.font_16 {
    font-size: 16px !important;
}

.emp_modal .form-group input,
.emp_modal .form-group select {
    border: none;
    background: #f5f5f5;
}

.emp_modal .form-group input:focus,
.emp_modal .form-group select:focus {
    border: 1px solid #ced4da;
    background: #FBFBFB;
}

.css-26l3qy-menu {
    z-index: 15 !important;
}

.ins_ul_list {
    list-style: unset;
    padding: 0px 20px;
}

::placeholder {
    color: #C5C5C5 !important;
    opacity: 1 !important;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C5C5C5 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C5C5C5 !important;
}

.css-1hwfws3 {
    border: 1px solid #cbcbcb30;
}

/* .bgv_profile div div{
    width: 100% !important;
  }  */

.profile_img_wrapper label {
    cursor: pointer;
}

.bgv_new_container .form-control {
    color: #777777 !important
}

.hide_content {
    display: none !important;
}

.location svg {
    margin-right: 5px !important;
}

.pt10 {
    padding-top: 10px;
}

.compress_upload .proof-doc {
    margin: 15px 0px !important;
}

#map #infowindow-content {
    display: inline;
}

.google_map_wrap #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.google_map_wrap .pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.google_map_wrap .pac-controls label {
    font-family: "Gilroy", sans-serif !important;
    font-size: 13px;
    font-weight: 300;
}

.google_map_wrap #pac-input {
    background-color: #fff;
    font-family: "Gilroy", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-left: 12px;
    padding: 3px;
    text-overflow: ellipsis;
    outline: none;
    padding-left: 5px !important;
    border: none;
    box-shadow: -3px 0px 6px 1px rgba(0, 0, 0, 0.13)
}

.google_map_wrap #pac-input:focus {
    border-color: #e62d3269;
}

.google_map_wrap .pac-target-input {
    z-index: 20 !important;
    position: absolute;
    left: 0px !important;
    top: 0px !important;
}

.google_map_wrap #pac-container {
    position: relative;
}

.google_map_wrap #pac-container .controls {
    z-index: 20 !important;
    position: absolute;
    left: 5px !important;
    top: 30px !important;
}

.map_inp_res {
    width: 25%;
}

@media screen and (max-width: 600px) {
    .map_inp_res {
        width: 70%;
    }
}

.single_doc_upload_preview {
    position: relative;
    padding: 25px;
    background: #8080801a;
    width: max-content;
}

.single_doc_upload_preview .remove_vendor {
    float: right;
    position: absolute;
    top: -8px;
    right: -9px;
}

.single_doc_upload_wrap {
    display: flex;
    justify-content: center;
}

.residence_candidate_verify .col-sm-6 {
    padding: 0px 15px 15px 15px;
}

.show_verify_data.title-hover .link-div {
    display: none;
    top: 35px;
    padding: 1rem 1rem;
    border-radius: 5px;
    box-shadow: -2px 1px 10px 0px rgba(0, 0, 0, 0.16);
    position: absolute;
    left: 0;
    width: 280px;
    background: #fff;
    z-index: 1;
}

.show_verify_data.title-hover:hover .link-div {
    display: block;
    z-index: 100;
}

.show_verify_data.title-hover li {
    margin-bottom: 2px;
}

.show_verify_data.title-hover li:last-child .clr5 {
    height: 0px;
}

.show_verify_data.title-hover .border-div {
    margin-bottom: 10px;
    width: 100%;
}

.show_verify_data.title-hover .body2 {
    font-weight: 500;
}

.displayNone {
    display: none;
}

.confirmation_h6_mr0 h6 {
    margin: 0px !important;
}

.address_autofill .pac-target-input {
    z-index: unset !important;
    position: relative;
}

.report_nav_header .navbar .navbar-nav .nav-item:last-child {
    padding-right: 20px;
}

.report_nav_header .navbar .navbar-nav .nav-item {
    padding: 0 20px;
}

.report_nav_header .subheader-nav {
    width: 100%;
}

.mt30 {
    margin-top: 28px;
}

.bt-billing {
    background-image: url(../images/icons/icon-file.svg);
    width: 24px;
    height: 24px;
    margin-right: 0px !important;
}

.billing_details_wrap.link-div {
    width: 410px !important;
}

.billing_details_wrap th {
    border: 0px;
    font-size: 14px;
    color: #41495c !important;
    font-weight: 600 !important;
    border: 0px;
    max-width: 150px;
}

.billing_details_wrap td {
    color: #2E384D !important;
    font-size: 14px;
    border: 0px;
    max-width: 150px;
}

/* .tableParent {
    border-left: 2px solid;
    background: #f1f5ff !important;
}
.tableParent td{
    font-weight: 600 !important;
} */

.tableChild td:first-child {
    display: list-item;
    list-style: disc inside;
    padding-left: 25px;
}

.tableChild td {
    color: #52484896 !important;
}

.newcompletetable .case-tbl .show_verify_data.title-hover.billing_hover .link-div td {
    padding: 1rem .75rem;
}

.newcompletetable .case-tbl .table tbody td.pointer {
    cursor: pointer !important;
}

.billing_hover {
    font-weight: 700;
    text-decoration: underline;
}

.case_creation_header .left-content {
    margin-top: 16px;
    padding: 0 20px 2rem 20px;
}

.report_preview_wrap .card {
    border: 0;
    background: transparent;
}

.report_preview_wrap .pack-accordion-head h6 {
    float: left;
    padding: 20px;
    font-weight: 400;
}

.report_preview_wrap .case-view-heading {
    font-weight: 500;
    color: #1b262bad;
    font-size: 18px;
}

.report_preview_wrap .card-body {
    padding: 1.25rem 2.25rem;
}

.form-group.displayFlex {
    display: flex;
    margin-bottom: 60px;
}

.form-group.displayFlex .body2 {
    width: 125px;
}

.form-group.displayFlex .form-control {
    margin-top: -10px;
    width: 250px
}

.panform .heading {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 25px;
}

.vl {
    border-left: 1px solid #e2e2e2;
    height: 500px;
}

.form-group.employment-otp {
    width: 450px;
    margin-left: 260px;
    padding: 26px;
}

.imageOtp {
    width: 500px;
    height: 300px;
    text-align: center;
    margin-left: 200px;
    margin-bottom: 30px;
}

.tds-verification ul {
    list-style: square !important;
}

.tds-verification {
    margin-top: 30px;
    font-size: 14px;
}

.tds-verification li {
    margin-bottom: 65px;
}

.datepicker_inline_display {
    width: unset;
    margin-left: 20px;
}

.upload_digiSign {
    height: 240px;
    justify-content: center;
}

.h70 {
    height: 70px !important;
}

.report_preview_wrap .info-pack-details {
    width: 125px;
    left: 65px;
}

.inline_date_picker .react-datepicker-wrapper {
    width: unset;
    margin-left: 0px;
}

.inline_date_picker label {
    display: inline;
    margin-right: 20px;
    width: 170px;
    color: #212529;
    opacity: 0.7;
    margin-left: 30px;
}

.loginbg {
    z-index: -1;
}

.cv_details_wrap {
    padding: 0px;
}

.cv_details_wrap .card {
    border: 0;
    background: transparent;
}

.cv_details_wrap .pack-accordion-head h6 {
    float: left;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
}

.cv_details_wrap .case-view-heading {
    font-weight: 500;
    color: #1b262bad;
    font-size: 18px;
}

.cv_details_wrap .card-body {
    padding: 0px
}

.cv_details_wrap .pack-accordion-head {
    padding: 10px 0px 0px 0px;
    background: #dedede59;
}

.cv_details_wrap .clr20 {
    height: 0px;
}

.cv_details_wrap .pack-accordion-head img {
    margin-right: 10px;
    margin-top: 5px;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
}

/* .create_pack.tb__tab-content.hide{
    display: none;
} */

.report_preview_wrap .accordion .card {
    overflow: inherit;
}

.emp_ck_Wrapper .cke_contents {
    height: 130px !important;
}

.bg_transparent {
    background: transparent;
}

.border_none {
    border: none;
}

.img_button {
    border: none;
    background: transparent;
}

.img_button:active,
.img_button:focus {
    outline: none;
}

.button_buttonType {
    border: none;
    background: transparent;
}

.button_buttonType:active,
.button_buttonType:focus {
    outline: none;
}

.sub_header_nav {
    padding: 10px;
    overflow: hidden;
    color: #454d66 !important;
    background-color: #fff;
}

.sub_header_nav .btn_navbtn {
    margin: 0px 10px;
    color: #454d66 !important;
}

.btn_navbtn:hover {
    color: #fff !important;
    background-color: #454d66;
}

.sub_header_nav .active {
    color: #fff !important;
    background-color: #454d66;
}

.inlinedisplay_block {
    display: inline-block;
}

.invoice_overview .border_right {
    border-right: 1px solid #80808042;
}

.compress_upload .upload-file {
    height: 145px;
}

.file-wrapper.styleDisable:hover {
    box-shadow: none !important;
}

.file-wrapper.styleDisable input,
.styleDisable input,
.styleDisable .checkmark {
    cursor: default;
}

.invoice_overview {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    padding: 32px 24px;
}

.invoice_overview_card {
    width: 28%;
    float: left;
    position: relative;
    flex: 0 0 28%;
    max-width: 28%;
    padding-right: 15px;
    /* padding-left: 15px; */
    box-sizing: border-box;
}

.label_div {
    font-size: 13px;
    color: #3d4b72
}

.billing_details_chart {
    width: 72%;
    float: left;
    position: relative;
    flex: 0 0 72%;
    max-width: 72%;
    padding-right: 0px;
    padding-left: 15px;
    box-sizing: border-box;
}

.billing_heading1 {
    font-size: 20px;
    color: #2E384D;
}

img.arrow_left {
    transform: rotate(90deg);
}

img.arrow_right {
    transform: rotate(270deg);
}

.billing_chart_li li {
    font-size: 14px;
    display: inline-block;
    margin: 5px 20px 5px 0px;
    color: #2E384D;
    /* cursor: pointer; */
}

.billing_client_wrap {
    padding: 10px;
}

.billing_chart_li .sub-heading1 {
    color: #2E384D;
    font-size: 24px;
}

.clientList_height {
    height: 400px;
    overflow: auto;
}

.grid_list_switch {
    display: flex;
    height: 60px;
    padding: 11px 15px;
    width: 120px;
    /* margin-left: auto; */
}

.grid_list_switch button {
    border: 1px solid #e2e2e2;
    background: #fff;
    min-width: 50%;
    flex: 0 0 50%;
    height: auto;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none !important;
}

.grid_list_switch button:hover {
    background: transparent;
}

.grid_list_switch button.active {
    background: #454d66;
}

.grid_list_switch button.active svg {
    fill: #fff;
    opacity: 1;
}

.grid_list_switch button svg {
    height: 17px;
    opacity: 0.6;
}

.emp_case_list.badge-td {
    padding: 13px 0px 0px 0px !important;
}

.emp_case_list .badge {
    font-size: 12px;
}

.emp_case_list .view_primary_btn {
    padding: 8px 23px;
}

.emp_grid_view .adminbg table td {
    padding: 15px 10px;
}

.limit_str {
    white-space: nowrap;
    max-width: 250px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
}

/* .billing_client_list{
    box-shadow: 0 8px 16px rgba(0,0,0,.05);
} */

.billing_status_btn {
    padding: 4px 8px;
    background-color: #2C65F0;
    font-size: 12px;
    color: #fff !important;
    border-radius: 2px;
}

.billing_status_btn.danger {
    background-color: #fe6666;
}

.billing_status_btn.received {
    background-color: #26C9A8;
}

.sub-heading5 {
    font-size: 16px;
    color: #2E384D;
}

.client_billing_layout {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    height: 300px;
    padding: 25px 20px 30px 20px;
    position: relative;
    overflow: auto;
}

.client_billing_layout .footer {
    position: absolute;
    bottom: 25px;
}

.billing_heading2 {
    font-size: 16px;
    color: #2E384D;
}

.client_billing_layout .pack_list {
    padding: 15px 0px;
}

.client_billing_layout .new_invoice {
    border: 1px solid #26B789;
    background: #ffffff00;
    color: #26B789;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 5px;
}

div:focus {
    border: none !important;
}

.emp_client_name .header-three-line-text {
    margin-top: -3px;
    margin-left: 3px;
}

.invoice_overview .highlight:hover {
    cursor: pointer;
}

.billing_table_wrap {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
}

.download_emp_xls {
    margin-top: 10px;
    margin-right: 10px;
}

.client_billing_layout .border_right {
    border-right: 1px solid #80808042;
}

.new_invoice_wrap {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
}

.new_invoice_wrap .col-6 {
    padding-right: 0px;
}

.new_invoice_wrap_btn .btn_secondary_light.btn {
    min-width: 100%;
}

.client_billing_flip .front,
.client_billing_flip .back,
.flipwrap .front,
.flipwrap .back {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    height: 300px;
    padding: 25px 20px 30px 20px;
    overflow: auto;
}

.client_billing_flip .front,
.flipwrap .front {
    z-index: 1;
}

.flip-card {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    transition: transform 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.flip-card>div {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card .back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.flip-card.flipped {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.create_invoice.modal-body {
    padding: 0px 45px;
}

.create_invoice .clr15 {
    height: 0px;
}

.flip-card.flipped .back {
    z-index: 2;
}

.bold {
    font-weight: bold;
}

.case_cost_wrap {
    background-color: #f5f5f5;
    padding: 20px 5px;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-items: center;
}

.light_border_top {
    border-top: 1px solid #ececec !important;
}

.billing_details_wrap .light_border_top {
    color: #2e384d;
    font-weight: 700;
}

.billing_table_wrap .clear {
    color: #26C9A8;
}

.billing_table_wrap .invoiced {
    color: #2C65F0;
}

.billing_table_wrap .danger {
    color: #fe6666;
}

.single_case_creation.nav-tabs {
    border-bottom: 0px;
}

.border_top_light {
    border-top: 1px solid #dee2e6;
}

.followUp_mail {
    right: 0px;
    width: fit-content;
    left: unset;
}

.emp_grid_view {
    padding: 20px 30px;
    background: #fff;
}

.mb10 {
    margin-bottom: 10px;
}

.border_bottom_light {
    border-bottom: 1px solid #ececec !important;
}

.hover_header:last-child {
    margin-right: 0;
}

.hover_content {
    display: none;
}

.hover_header:hover .hover_content {
    display: block;
    top: 35px;
    padding: 1rem 1rem;
    border-radius: 5px;
    box-shadow: -2px 1px 10px 0px rgba(0, 0, 0, 0.16);
    position: absolute;
    left: 0;
    width: 280px;
    background: #fff;
    z-index: 1;
}

.seperateComponent {
    border-bottom: 1px solid #e7e7e7;
}

.droppableDiv [data-rbd-drag-handle-context-id] {
    background: #fff !important;
    border: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: 1px solid #e7e7e7 !important;
    height: 100%;
    width: 100%;
}

.droppableDiv [data-rbd-droppable-id] {
    background: transparent !important;
    padding: 0px !important;
}

.fixedColumn {
    overflow: auto;
    padding: 0px 15px;
}

.seperateCompany {
    background: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    margin-bottom: 30px;
}

.companytitle {
    color: #2e384d;
    font-weight: 700;
    font-size: 20px;
}

.droppableDiv [data-rbd-drag-handle-context-id]:focus {
    outline: none !important;
}

.seperateComponent ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.seperateComponent ul li {
    width: 128px;
    height: 128px;
    margin: 0px 15px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seperateComponent ul li img {
    width: 100%;
    max-height: 110px;
    height: 100%;
}

.fileName {
    padding: 0px 10px 10px;
    text-align: center;
}

.seperateComponent ul li .withFileName img {
    max-height: 90px;
}

.seperateComponent ul:empty {
    position: relative;
    height: 130px;
}

.seperateComponent ul:empty:after {
    position: absolute;
    content: 'Drag your files here';
    color: #737373;
    text-align: center;
    border: 1px dashed #e7e7e7;
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seperateAttachment {
    background: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    margin-bottom: 30px;
}

.seperateAttachment .sub-heading2 {
    padding: 15px;
    background: #eceef3;
}

.seperateAttachment ul {
    padding: 20px 20px 0px;
}

.seperateComponent.seperateAttachment ul:empty {
    padding: 0px;
    width: 95%;
    margin: auto;
    padding-top: 20px;
}

.add-document-modal.source_tagging>.modal-dialog {
    width: 95%;
    max-width: 100%;
}

.seperateComponent.ignoreEmpty ul:empty:after {
    content: '';
}

.pad15 {
    padding: 15px;
}

.seperateComponent .border_right {
    border-right: 1px solid #80808042;
}

.seperateComponent~div:empty {
    height: 0px !important;
}

.reportShare_badge {
    padding: 3px 10px;
    border-radius: 26px;
    min-width: 73px;
    font-size: 12px;
    font-weight: normal;
    color: #454d66;
    background: #fff;
    border: 1px solid #454d66;
}

.reportShare_badge.reportShared_badge {
    background: #454d66;
    color: #f7f7f7;
}

.bgv_profile.center {
    margin: auto;
    width: 50%;
}

.source_tagging .modal-header {
    padding: 32px 32px 0px 32px;
}

.source_tagging .modal-body {
    padding-top: 0px;
}

.source_tagging .sub-heading2 {
    margin-bottom: 20px;
}

/* .source_tagging .droppableDiv .sub-heading2{

} */

.download_other_zip {
    display: flex;
    justify-content: space-between;
}

.download_other_zip .unidentified-btn {
    padding: 0px;
}

.download_other_zip .file-btn svg {
    position: absolute;
    right: 3px;
    border-radius: 100%;
    padding: 7px;
    top: -7px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

ul.billing_pack_wrap {
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.billing_pack_wrap li {
    /* float: left; */
    display: flex;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.btn_secondary_light.disable_hover:hover {
    color: #454d66 !important;
    background-color: #f5f5f5 !important;
}

.css-1hwfws3 {
    border: 0px;
}

.bgv_download {
    background: #f2f2f2;
    width: 150px;
    font-size: 12px;
    border-radius: 20px;
    padding: 7px 11px;
    position: relative;
    margin-top: 0px;
    float: left;
}

.file-btn.bgv_download svg {
    position: absolute;
    right: 2px;
    border-radius: 100%;
    padding: 5px;
    top: 3px;
    height: 80%;
    width: 28px;
    cursor: pointer;
}

.bgv_download span {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .6px;
}

.bgv_download button {
    border: 0px !important;
    padding: 0px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .6px;
    outline: none;
}

.bgv_download button:focus {
    outline: none;
}

.bgv_download.download-btn .disable_hover svg {
    pointer-events: none;
}

.bgv_download .smallfont {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .6px;
    cursor: default;
}

.bgv_download .disable_hover {
    opacity: 0.5;
    color: grey;
}

.border_btm {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

h6.border_btm {
    font-size: 18px;
}

.bt-pack-approval {
    height: 18px;
}

.case_report_dropdown span {
    margin-right: 10px;
}

.case_report_dropdown .inputDiv {
    width: 30px;
    margin: 0px;
    padding: 10px 10px 10px 20px;
    height: 30px;
    border: 0px;
    border-left: 1px solid #e2e2e2;
    background: none;
}

.case_report_dropdown ul {
    width: 180px;
    left: inherit;
    right: 0px;
    top: 30px;
}

.case_report_dropdown .inputDiv:before {
    content: '';
    position: absolute;
    background-image: url('../images/icons/icon-select-arrow.svg');
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    right: 7px;
    top: 4px;
    cursor: pointer;
}

@media (min-width: 576px) {
    .pack_info_modal .modal-dialog {
        max-width: 750px;
        margin: 1.75rem auto;
    }
}

.packdetailstab tr th:first-child {
    width: 220px;
}

.followup_count {
    border: 1px solid #80808061;
    border-radius: 100%;
    display: inline-block;
    height: 25px;
    width: 25px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.myLocationWrap {
    background-color: rgb(255, 255, 255);
    border: none;
    outline: none;
    width: 40px;
    height: 35px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
    cursor: pointer;
    margin-right: 10px;
    padding: 0px;
    padding-left: 6px;
    padding-top: 3px;
}

.myLocationIcon {
    margin: 5px;
    width: 18px;
    height: 18px;
    background-image: url('https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png');
    background-size: 180px 18px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
}

.sort_th.asc:after {
    background-image: url('../images/icons/sort-ascending.svg');
    background-position: 5px 3px;
}

.sort_th.desc:after {
    background-image: url('../images/icons/sort-descending.svg');
    background-position: 5px 3px;
}

.emp_management_layout .commontbl.case-tbl {
    overflow: auto;
    width: 100%;
}

.text_fade {
    opacity: 0.3;
}

.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.profile-form .plus--icon {
    margin-left: 10px;
    height: 22px;
    width: 24px;
}

.operations-th {
    width: 150px;
    font-weight: bold !important;
}

.operations-th-small {
    width: 15px;
    margin-left: 17px;
}

.doctor.insufficiency {
    background-image: url(../images/icons/doctor-insufficiency.svg);
    width: 20px;
}

.doctor.in-progress,
.doctor.yet-to-start {
    background-image: url(../images/icons/doctor-in-progress.svg);
    width: 20px;
}

.doctor.clear {
    background-image: url(../images/icons/doctor-clear.svg);
    width: 20px;
}

.doctor.suspicious {
    background-image: url(../images/icons/doctor-suspicious.svg);
    width: 20px;
}

.doctor.failed {
    background-image: url(../images/icons/doctor-failed.svg);
    width: 20px;
}

.doctor {
    background-image: url(../images/icons/doctor.svg);
    width: 20px;
}

.user_manage_wrap {
    display: flex;
    justify-content: center;
}

.user_manage_wrap .card {
    min-height: 150px;
    justify-content: center;
    text-align: center;
    border: 2px solid #e8e8e8;
    background-color: #f0f0f0;
    cursor: pointer;
}

.user_manage_wrap .card:hover {
    background-color: #fff;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.1);
}

.user_manage_wrap .card.active {
    background-color: #fff;
}

.reportShare_badge.clear {
    background-color: #26b789;
    border: 0px;
    color: #fff;
}

.reportShare_badge.primary_color {
    background-color: #454d66;
    border: 0px;
    color: #fff;
    text-align: center;
}

.bt__label-group .label {
    border-radius: 25px;
    background-color: #f2f2f2;
}

.adminstration_layout.admin-row .right-side {
    background-color: #fff !important;
    box-shadow: none;
}

.adminstration_layout .user-card {
    background-color: #f5f5f5;
}

.adminstration_layout .navbar .navbar-nav .nav-item:last-child {
    padding: 0px 30px;
}

.pack_approval_card .user-card {
    background-color: #f5f5f5 !important;
}

.case_management_card .padding-card {
    background-color: #f5f5f5;
}

.adminstration_layout .badge.pack-badge {
    background-color: #fff;
    padding: 5px;
    color: #26b789;
    border: 1px solid #26b789;
}

.adminstration_layout .pack-badge.badge.inprogress {
    background-color: #fff;
    padding: 5px;
    color: #efa003;
    border: 1px solid #efa003;
}

.adminstration_layout .bt__label-group .inprogress {
    background-color: #fff;
    padding: 5px;
    color: #efa003;
    border: 1px solid #efa003;
}

#bt-snackbar-wrapper {
    position: fixed;
    bottom: -100px;
    right: inherit;
    transition: all 0.5s linear;
    width: 100%;
    top: inherit;
}

.bt-snackbar {
    transition: all .5s linear;
    background-color: #000;
    width: fit-content;
    margin: 0 auto;
}

.bt-snackbar .icon-with-text {
    color: #fff;
    font-weight: initial;
}

#bt-snackbar-wrapper.snackActive {
    bottom: 50px
}

.user_management_list .col-6 {
    padding: 0px;
}

.loading_spinner {
    padding: 0px;
    top: 60%;
    position: absolute;
    width: 100%;
    text-align: center;
}

.bg_code_tds_completed {
    color: #fff;
    background: #487cbd !important;
    letter-spacing: 0.5px;
}

.bgv_download_btn {
    margin-top: 3px !important;
    padding: 1px 11px;
    font-size: 10px;
}

.bgv_download_btn .file-btn {
    width: 230px;
    border-radius: 20px;
    position: relative;
    float: left;
    margin-top: 0px;
    padding: 0px;
}

.bgv_download_btn .file-btn svg {
    height: 75%;
    position: relative;
}

.vendor_cost_spread,
.check_wise_spread,
.chp_stats {
    width: 32%;
    float: left;
    position: relative;
    /* flex: 0 0 30%; */
    max-width: 32%;
    box-sizing: border-box;
    height: 515px;
}

/* .vendor_cost_spread .case-tbl .table tbody td  */

.check_wise_spread .case-tbl .table tbody td,
.chp_stats .case-tbl .table tbody td {
    width: 120px;
    word-break: break-word;
}

.check_wise_spread .padding-card,
.vendor_cost_spread .padding-card,
.chp_stats .padding-card {
    height: 500px;
}

.check_wise_spread {
    width: 36%;
    max-width: 36%;
    padding: 0px 15px;
}

.check_wise_spread_chart {
    width: 400px !important;
    height: 400px !important;
}

.pb20 {
    padding-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.tableBuilder {
    padding: 10px 0px;
}

.tableBuilder.case-tbl .table thead th {
    padding: 10px !important;
    color: #a0a0a0 !important;
}

.tableBuilder .green {
    color: #2e744c !important;
}

.tableBuilder .red {
    color: #E03B24 !important;
}

.light_bg_red {
    background-color: #ffecee;
}

.billing_client_wrap .user-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
    cursor: pointer;
}

.billing_client_wrap .user-card .bt__img-text-group .text-wrapper {
    width: calc(100% - 50px);
}

.billing_client_wrap .user-card {
    min-height: 220px;
}

.billing_layout .sub_header_nav button {
    text-transform: unset;
}

.pl0 {
    padding-left: 0px;
}

.modal.mini-form {
    width: 50%;
    margin-left: 23%;
}

.mini-underline-text {
    text-decoration: underline;
    color: #989898 !important;
    cursor: pointer;
}

.drug-address {
    margin-top: -15px;
    display: flex;
    float: right;
}

.choose_role_container {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
}

.choose_role_container .user-card img {
    height: 150px;
}

.choose_role_container .user-card {
    width: 300px;
    height: 300px;
    border-radius: 4px;
    margin: 0px 20px;
    display: inline-block;
    cursor: pointer;
}

.choose_role_container .user-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05);
}

.choose_role_wrap {
    background-color: #e6e6e6;
    min-height: 100vh;
    padding-bottom: 60px;
}

.choose_role_container .companylogoclass {
    padding-top: 50px;
    margin-bottom: 25px;
    width: unset;
}

.choose_role_sub {
    margin-bottom: 25px;
}

.table_according_toggle {
    background-image: url(../images/icons/icon-down-arrow.svg);
    height: 20px;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
    transform: rotate(270deg);
}

.tableBuilder .accordion button {
    background: transparent;
    border: 0px;
}

.tableBuilder .accordion .card-header {
    padding: 0px;
}

.tableBuilder button {
    outline: none;
    padding: 0px;
}

.tableBuilder .accordion .card-header {
    padding: 0px;
    background: none;
    border: 0px;
}

.tableBuilder .accordion .card {
    border: 0px;
}

.tableBuilder .accordion .active .table_according_toggle {
    transform: rotate(0deg);
}

.tableBuilder .accordion .outer_td_wrap {
    padding: 1.3rem 0px;
    width: 32px !important;
}

/* .tableBuilder .accordion .collapse{
display: none;
 }
.tableBuilder .accordion .active .collapse{
    display: block;
} */

.case-tbl .table thead .no_border th {
    border: 0px !important;
}

.tableheaderfont .dark_header th {
    font-weight: 600 !important;
}

.adminstration_layout .user_management_list .user-card {
    background-color: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
}

.adminstration_layout .user-card.blocked_bg {
    background-color: #ececec;
    border: 0px;
}

.billing_table_wrap .case-row td {
    max-width: 120px;
}

.billing_table_wrap .case-row .straight_td {
    width: 100%;
}

.operations_slick_wrapper {
    border-bottom: 1px solid #dee2e6 !important;
    padding: 0px 40px;
    margin-top: 20px !important;
}

.operations_slick_wrapper .btn-link {
    color: #454d66 !important;
    text-align: center !important;
}

.operations_slick_wrapper .active button,
.operations_slick_wrapper .active .nav-link:hover,
.operations_slick_wrapper .active .nav-link:focus,
.operations_slick_wrapper .active .nav-link:visited {
    border-bottom: 2px solid #454d66 !important;
}

.operations_slick_wrapper .slick-arrow.slick-disabled {
    opacity: 0px;
    display: none !important;
}

.operations_slick_wrapper .slick-prev:before,
.operations_slick_wrapper .slick-next:before {
    font-size: 30px;
    line-height: 0.5;
    color: #bbbbbb;
}

.cibil_doc_upload .white-bg.row {
    padding: 5px;
}

.add-document-modal.reset_password_modal>.modal-dialog {
    width: 35%;
    ;
}

.reset_password_modal .modal-body {
    padding-top: 0px;
}

.reset_password_modal .css-2b097c-container {
    margin-bottom: 2rem;
}

.delete_bg_icon {
    background-image: url(../images/icons/icon-delete.svg);
    width: 25px;
    height: 22px;
    background-repeat: no-repeat;
    display: none;
}

.user-card:hover .delete_bg_icon {
    display: inline-block;
    margin-bottom: -8px;
    position: absolute;
    right: 20px;
}

.operations_slick_wrapper .slick-prev {
    left: -35px;
}

.pd-20 {
    padding: 20px !important;
}

.mini-text {
    color: #989898 !important;
    cursor: pointer;
}

.overall_count.de-view .white-bg {
    background-color: #ffffff;
}

.cost_approval .switch input+span:before {
    top: 2px;
    left: 0;
    width: 42px;
    height: 21px;
}

.cost_approval .switch input+span:after {
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
}

.cost_approval .switch input+span strong:before {
    content: "Yet to be approved ";
}

.cost_approval input+span strong:after {
    content: "Approved" !important;
}

.role_status.cost_approval .switch input+span strong:before {
    content: "In Active" !important;
}

.role_status.cost_approval input+span strong:after {
    content: "Active" !important;
}

.role_status.cost_approval .switch.verification input+span strong:before {
    content: "" !important;
    display: flex;
}

.role_status.cost_approval .switch.verification input+span strong:after {
    content: "" !important;
}

.bgColor_danger {
    background-color: #fe6666;
    color: #fff;
    border: 0px !important;
}

.bgColor_suspicious {
    background-color: #ffa724;
    color: #fff;
    border: 0px !important;
}

.client_select_wrap .css-yk16xz-control {
    margin: 3px;
}

.role_list_container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 20px 10px;
}

.role_list_wrapper {
    width: 100%;
    padding: 0px 15px;
}

.adminstration_layout .css-yk16xz-control {
    border: 1px solid #efefef;
}

.role_list_container .row {
    margin: 0px;
}

.user_management_list .reportShare_badge {
    margin: 5px;
}

.reportShare_badge.reduce_opacity {
    opacity: 0.5;
}

.choose_role_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.choose_role_wrapper .user-card {
    margin: 10px;
}

.clr30 {
    height: 30px;
}

.sub_heading_mar {
    margin: 25px 0px 15px 0px;
}

.invoice_preview_table tbody {
    display: block;
    max-height: 500px;
    overflow: auto;
}

.invoice_preview_table thead,
.invoice_preview_table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.invoice_preview_table thead {
    width: calc(100% - 1em)
}

.invoice_preview_table tr,
.invoice_preview_table td {
    cursor: default;
}

.invoice_preview_table th {
    border-bottom: 0px;
}

.emp_accordion_container .card {
    border: 0;
    background: transparent;
}

.emp_accordion_container .pack-accordion-head h6 {
    float: left;
    padding: 20px;
    font-weight: 400;
}

.emp_accordion_container .case-view-heading {
    font-weight: 500;
    color: #1b262bad;
    font-size: 18px;
}


.emp_accordion_container .info-pack-details {
    width: 125px;
    left: 65px;
}

.emp_accordion_container .accordion .card {
    overflow: inherit;
}

.emp_accordion_container .pack-accordion-head {
    padding: 0px;
    padding-top: 5px;
}

.emp_accordion_container {
    padding: 0px;
}

.emp_accordion_container h6 {
    padding: 15px;
}

.emp_accordion_container .collapse .card-body {
    padding: 0px;
    margin-bottom: 10px;
}

.form_control_bg.form-control {
    background-color: #ffff;
}

.form_control_bg .form-control {
    background-color: #ffff;
}


.paid-status .switch input+span strong:before {
    content: "Show Paid Cases";
}

.paid-status input+span strong:after {
    content: "Show Paid Cases" !important;
}

.paid-status .switch input+span:before {
    top: 2px;
    left: 0;
    width: 42px;
    height: 21px;
}

.paid-status .switch input+span:after {
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
}

.vendor_cost_table .case-row td {
    cursor: default;
}

.directorship.insufficiency {
    background-image: url(../images/icons/directorship-insufficiency.svg);
    width: 20px;
}

.directorship.in-progress,
.directorship.yet-to-start {
    background-image: url(../images/icons/directorship-in-progress.svg);
    width: 20px;
}

.directorship.clear {
    background-image: url(../images/icons/directorship-clear.svg);
    width: 20px;
}

.directorship.suspicious {
    background-image: url(../images/icons/directorship-suspicious.svg);
    width: 20px;
}

.directorship.failed {
    background-image: url(../images/icons/directorship-failed.svg);
    width: 20px;
}

.directorship {
    background-image: url(../images/icons/directorship.svg);
    width: 20px;
}

.hw24 {
    height: 24px;
    width: 24px;
}

.add-document-modal.uan-verification>.modal-dialog {
    width: 40%;
    max-width: 100%;
}

.uan-input-div .body2 {
    font-weight: 600;
    font-size: 15px;
}

.uan-input-div input {
    font-size: 15px;
    height: 48px;
}

.content-center-align {
    text-align: center;
}

.no-docs-span {
    font-weight: 600;
    font-size: 15px;
}

.results-heading {
    font-weight: 600;
    font-size: 18px;
}

.document-viewer .results-header {
    display: flex;
    margin-left: 40px;
}

.document-viewer .pack-accordion-head h6 {
    float: left;
    padding: 20px;
    font-weight: 400;
}

.interim-report-checkbox {
    position: absolute;
    right: 25px;
}

.hover-show {
    display: none;
}

.interim-report-checkbox:hover .hover-show {
    display: block;
}

.notification-content {
    border: 1px solid #454d66;
    border-radius: 4px;
    padding: 10px;
}



.mis-overall-dashboard .table td.values,
.mis-overall-dashboard .table thead th {
    text-align: center;
}

.mis-weekly-report .table td.values,
.mis-weekly-report .table thead th {
    text-align: center;
}

.mis-business-split .table td.values,
.mis-business-split .table thead th {
    text-align: center;
}

.mis-parameter-based .table td.values,
.mis-parameter-based .table thead th {
    text-align: center;
}

.navbar .horizontal-tab-nav.navbar-nav .nav-item:last-child {
    padding-right: 30px;
}

.sub-text-color {
    color: #888888
}

.vertical-align-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon-hover {
    position: relative;
}

.icon-hover .icon-details {
    display: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    padding: 15px 20px;
    border-radius: 4px;
    position: absolute;
    top: 23px;
    width: 150px;
    background: #fff;
    z-index: 1;
}

.icon-hover:hover .icon-details {
    display: flex;
}

.hover-right {
    left: unset;
    right: 0;
}

.report_preview_wrap .proof-doc-li {
    display: inline-block;
    margin: 10px;
}

.report_preview_wrap .proof-doc {
    position: relative;
    overflow: hidden;
    min-height: 100px;
    max-height: 150px;
    max-width: 200px;
}

.report_preview_wrap .proof-doc img.proof-img {
    max-height: 150px;
    max-width: 200px;
}

tr.hide {
    display: none;
}

tr.show {
    display: table-row;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.qcDetails {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 20px 15px 15px 25px;
}

.uploadByQc {
    margin-top: 4px;
}

.qcDetails .reportpreviewcard {
    padding: 20px 10px 0px 10px !important;
}

.centered-row-contents {
    display: flex;
    align-items: center;
}

.more-options-btn {
    background: none;
    border: none;
    padding: 0 5px;
    cursor: pointer;
}

.more-options {
    position: relative;
}

.more-options .action-option {
    display: none;
    position: absolute;
}

.more-options .action-option.active {
    display: block;
}

.more-options .action-option ul {
    background: white;
    border: 0.5px solid #e4e4e4;
    border-radius: 2px;
}

.more-options .action-option li {
    color: #3d4b72;
    font-weight: 400;
    line-height: 14px;
    padding: 10px 15px;
    cursor: pointer;
}

.more-options .action-option.action-right {
    right: 0;
}

.excel-icon {
    background-image: url("../images/icons/excel.png");
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.more-options-text {
    font-weight: 600;
    color: #888888;
    cursor: pointer;
    display: flex;
}

.more-options .down-arrow {
    content: '';
    background-image: url(../images/icons/icon-select-arrow.svg);
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    padding-bottom: 24px;
}

.edit-icon {
    content: '';
    background-image: url(../images/icons/icon-edit.svg);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
}

.menu-disabled {
    color: #8c8c8c;
    cursor: not-allowed;
}

.table.qcOperations th {
    width: 3%;
}

.table.qcOperations th.medium {
    width: 10%;
}

.relative {
    position: relative;
}

#includeSv-checkbox-div {
    position: absolute;
    left: 17px;
}

.case-navs .btn-link.verified {
    color: #249200 !important;
}

.first-interim-report-checkbox {
    position: absolute;
    right: 45px;
}

.first-interim-report-checkbox:hover .hover-show {
    display: block;
}

.flex-vertical-align {
    align-items: center;
}

@media(max-width: 600px) {
    .time_line_chart .rel {
        display: none;
    }

    .navbar-expand .navbar-nav {
        flex-direction: column;
    }

    .navbar-toggler {
        display: block !important;
    }
}

.auto-scroll {
    overflow: auto;
}

.include-report-shared {
    position: absolute;
    right: 15px;
    top: 9px
}

.operations-tat-badge {
    padding: 7px 5px 5px 5px;
}



.grid-card,
.grid-list {
    position: relative;
}

/* common */
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.grid-card .ribbon {
    width: 70px;
    height: 70px;
}

td .ribbon {
    width: 36px;
    height: 36px;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.grid-card .ribbon::before,
.grid-card .ribbon::after,
td .ribbon::before,
td .ribbon::after {
    border: 5px solid #d32f2f;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

.grid-card .ribbon span {
    position: absolute;
    display: block;
    width: 170px;
    padding: 3px 0;
    background-color: #ef5350;
    ;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    color: #fff;
    font: 700 9px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: uppercase;
    text-align: center;
}

td .ribbon span {
    position: absolute;
    display: block;
    width: 170px;
    padding: 2px 0;
    background-color: #ef5350;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    color: #fff;
    font: 700 6px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

td .ribbon-top-left {
    top: 0px;
    left: 0px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

.grid-card .ribbon-top-left span {
    right: -46px;
    top: 21px;
}

td .ribbon-top-left span {
    right: -60px;
    top: 7px;
}

.floating-checkbox-height {
    height: 30px;
}

.first-ir-download-checkbox {
    position: absolute;
    right: 97px;
}

.ol-disc {
    list-style-type: disc;
}

.bold {
    font-weight: 800;
}

.red-text {
    color: red;
}

.progress-bar-wrapper {
    background-color: #f1f1f1 !important;
    border-radius: 5px;
    margin: 0 22px;
}

.progress-bar {
    height: 14px;
    border-radius: 7px;
    background-color: #59908a !important;
}

.dv-upload-modal .dv-upload-label {
    height: 100px;
}

.dv-row.beyondTat,
.bg-beyondTat {
    background-color: #ffcdd2 !important;
}

.dv-row td {
    vertical-align: middle;
}

.comment-display {
    max-height: 150px;
    overflow-y: auto;
}

.slider-item {
    padding: 5px;
    text-align: center;
}

.slider-item.active {
    border-bottom: 2px solid #454d66 !important
}

.max-dropdown {
    min-width: 50px;
}

.flex-dropdown {
    min-width: 250px;
}

.flex-bottom {
    display: flex;
    align-items: flex-end;
}

.subtable tr {
    background-color: #f5f5f5;
    cursor: default !important;
}

.subtable {
    background-color: #f5f5f5;
    cursor: default !important;
}

.toggle-arrow {
    transition: transform 0.7s;
}

.rotate180 {
    transform: rotate(180deg);
}

.subtable.inactive {
    display: none;
}

.top-priority-margin {
    margin-left: 26px;
}

.shorten-comment {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.horizontal-justify-center {
    justify-content: center;
}

span.link {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
}

.batch-card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.batch-card-footer .footer-item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.batch-bulk-xls-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.batch-details-head-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ml30 {
    margin-left: 30px;
}

.cancelled-bg td {
    color: #808080a3 !important;
    font-style: italic;
}

.batch-list-excel-icon {
    display: inline-block;
    height: 28px;
    width: 30px;
    background-image: url("../images/icons/excel.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.bt-settings-icon {
    background-image: url(../images/icons/settings.svg);
    width: 24px;
    height: 24px
}

.flex-float-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}