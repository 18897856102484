@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.woff') format('woff'),
         url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.woff') format('woff'),
         url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Gilroy', sans-serif;
}
