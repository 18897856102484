/*@import"https://fonts.googleapis.com/css?family=Gilroy:300,400,700&display=swap";*/
@import"gilroy.css";
.margin0 {
    margin: 0 !important
}

.mt0 {
    margin-top: 0 !important
}

.mt5 {
    margin-top: 5px !important
}

.mt10 {
    margin-top: 10px !important
}

.mt15 {
    margin-top: 15px !important
}

.mt20 {
    margin-top: 20px !important
}

.mr0 {
    margin-right: 0 !important
}

.mr5 {
    margin-right: 5px !important
}

.mr10 {
    margin-right: 10px !important
}

.mr15 {
    margin-right: 15px !important
}

.mr20 {
    margin-right: 20px !important
}

.mb0 {
    margin-bottom: 0 !important
}

.mb5 {
    margin-bottom: 5px !important
}

.mb10 {
    margin-bottom: 10px !important
}

.mb15 {
    margin-bottom: 15px !important
}

.mb20 {
    margin-bottom: 20px !important
}

.ml0 {
    margin-left: 0 !important
}

.ml5 {
    margin-left: 5px !important
}

.ml10 {
    margin-left: 10px !important
}

.ml15 {
    margin-left: 15px !important
}

.ml20 {
    margin-left: 20px !important
}

.left {
    float: left !important
}

.right {
    float: right !important
}

.rel {
    position: relative !important
}

.abs {
    position: absolute !important
}

.pad0 {
    padding: 0 !important
}

.pad5 {
    padding: 5px !important
}

.pad10 {
    padding: 10px !important
}

.pad15 {
    padding: 15px !important
}

.pad20 {
    padding: 20px !important
}

.pad25 {
    padding: 25px !important
}

.tpad0 {
    padding-top: 0 !important
}

.bpad0 {
    padding-bottom: 0 !important
}

.lpad0 {
    padding-left: 0 !important
}

.rpad0 {
    padding-right: 0 !important
}

.clr,
.clr5,
.clr10,
.clr15,
.clr20 {
    clear: both;
    display: block;
    width: 100%
}

.clr5 {
    height: 5px
}

.clr10 {
    height: 10px
}

.clr15 {
    height: 15px
}

.clr20 {
    height: 20px
}

html {
    overflow-x: hidden !important;
    scroll-behavior: smooth
}

.pointer {
    cursor: pointer !important
}

.block {
    display: block;
    width: 100% !important
}

ul {
    padding: 0;
    margin: 0;
    list-style: none
}

img {
    max-width: 100%
}

html,
body,
button,
input,
optgroup,
select,
textarea {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 500
}

h1,
h2,
h3,
h4,
h5,
.social-intelligence-wrapper .personality-detail .card-content .big-text,
h6,
.sub-heading1,
.social-intelligence-wrapper .persona-detail .primary-text,
.sub-heading2,
.notification-list .notification-content .ago-time,
.notification-list .notification-content .text-action a,
.notification-list .notification-content .notification-text p,
.user-card .bt__img-text-group .text-wrapper .name,
.sticky-slert .icon-with-text {
    color: #2e384d;
    font-weight: 700
}

h1 {
    font-size: 96px
}

h2 {
    font-size: 60px
}

h3 {
    font-size: 48px
}

h4 {
    font-size: 34px
}

h5,
.social-intelligence-wrapper .personality-detail .card-content .big-text {
    font-size: 24px
}

h6 {
    font-size: 20px
}

.sub-heading1,
.social-intelligence-wrapper .persona-detail .primary-text {
    font-size: 16px
}

.sub-heading2,
.notification-list .notification-content .ago-time,
.notification-list .notification-content .text-action a,
.notification-list .notification-content .notification-text p,
.user-card .bt__img-text-group .text-wrapper .name,
.sticky-slert .icon-with-text {
    font-size: 14px
}

.body1,
.social-intelligence-wrapper .persona-detail p,
.social-intelligence-wrapper .personality-detail .card-content .small-text {
    font-size: 16px;
    color: #3d4b72
}

.body2 {
    font-size: 14px;
    color: #3d4b72
}

.body3 {
    font-size: 13px;
    color: #3d4b72
}

.smallfont,
.company-wrapper .profile-form .change-profile-image label,
.profile-form .change-profile-image .change-image span,
.notification-list .notification-content .text-desc,
.added-label-group .label-list .label,
.action-group .action-option ul li,
.user-card p,
.user-card .bt__img-text-group .text-wrapper .user-name,
.bt__label-group .label,
.tag {
    font-size: 13px
}

.font-16 {
    font-size: 16px !important
}

.bt-icon {
    background-position: center center;
    display: inline-block;
    background-repeat: no-repeat
}

.bt-user-management {
    background-image: url(../images/icons/icon-user-management.svg);
    width: 24px;
    height: 24px
}

.bt-entity {
    background-image: url(../images/icons/icon-entity.svg);
    width: 24px;
    height: 24px
}

.bt-permission-group {
    background-image: url(../images/icons/icon-permission-group.svg);
    width: 24px;
    height: 24px
}

.bt-notifications {
    background-image: url(../images/icons/icon-notifications.svg);
    width: 24px;
    height: 24px
}

.bt-profile-settings {
    background-image: url(../images/icons/icon-profile-settings.svg);
    width: 24px;
    height: 24px
}

.bt-company-settings {
    background-image: url(../images/icons/icon-company-settings.svg);
    width: 14px;
    height: 16px
}

.bt-search {
    background-image: url(../images/icons/icon-search.svg);
    width: 12px;
    height: 12px
}

.bt-dribble-bot-v {
    background-image: url(../images/icons/icon-dribble-bot-v.svg);
    width: 12px;
    height: 24px
}

.bt-close {
    background-image: url("../images/icons/icon-close.svg");
    width: 14px;
    height: 14px
}

.sticky-slert .icon-with-text .success {
    background-image: url(../images/icons/icon-success-tick.svg);
    width: 24px;
    height: 24px
}

.sticky-slert .icon-with-text .wrong {
    background-image: url(../images/component-icons/component-close.svg);
    width: 24px;
    height: 24px
}

.bt-location {
    background-image: url(../images/icons/icon-location.svg);
    width: 42px;
    height: 35px
}

.bt-device {
    background-image: url(../images/icons/icon-device.svg);
    width: 50px;
    height: 43px
}

.bt-language {
    background-image: url(../images/icons/icon-language.svg);
    width: 50px;
    height: 26px
}

.bt-linkedin {
    background-image: url(../images/icons/icon-linkedin.svg);
    width: 60px;
    height: 60px
}

.bt-pinterest {
    background-image: url(../images/icons/icon-pinterest.svg);
    width: 60px;
    height: 60px
}

.bt-twitter {
    background-image: url(../images/icons/icon-twitter.svg);
    width: 60px;
    height: 60px
}

.bt-facebook {
    background-image: url(../images/icons/icon-facebook.svg);
    width: 60px;
    height: 60px
}

.id {
    background-image: url(../images/icons/id.svg)
}

.employment {
    background-image: url(../images/icons/employment.svg)
}

.education {
    background-image: url(../images/icons/education.svg)
}

.residence {
    background-image: url(../images/icons/residence.svg)
}

.drug {
    background-image: url(../images/icons/drug.svg)
}

.credit {
    background-image: url(../images/icons/credit.svg)
}

.database {
    background-image: url(../images/icons/database.svg)
}

.reference {
    background-image: url(../images/icons/reference.svg)
}

.court {
    background-image: url(../images/icons/court.svg)
}

.social {
    background-image: url(../images/icons/social.svg)
}

.metapath {
    background-image: url(../images/icons/metapath.svg)
}

.table thead th {
    font-weight: 500;
    color: #454d66
}

.table th,
.table td {
    border-color: #f1f1f3
}

.batch-tbl th {
    border: 0 !important;
    padding-bottom: 2rem
}

.batch-tbl td {
    border-top: 0 !important;
    border-bottom: 1px solid #d5d5d5;
    color: #3d4b72;
    font-size: 16px;
    font-weight: 500
}

.batch-tbl tr:last-child td {
    border-bottom: 0
}

.case-tbl .table thead th {
    color: #d5d5d5;
    font-weight: 200;
    border: 0 !important;
    border-bottom: 1px solid #f1f1f3 !important;
    padding-bottom: 1.5rem
}

.case-tbl .table tbody td {
    color: #3d4b72;
    font-size: 16px;
    padding: 1.3rem .75rem
}

.table-hover tbody tr:hover {
    background-color: #fff;
    cursor: pointer
}

.exceltbl {
    background-color: #fff;
    border: 1px solid #d5d5d5
}

.exceltbl select.error {
    background: #ffe2e7
}

.exceltbl table {
    border-color: #bfbfbf;
    margin-bottom: 0
}

.exceltbl table th,
.exceltbl table td {
    border-right: 1px solid #bfbfbf
}

.exceltbl table td {
    padding: 0;
    border-top: 1px solid #bfbfbf
}

.exceltbl table td svg {
    position: absolute;
    left: -26px;
    height: 15px;
    top: 7px;
    fill: #fe6666;
    cursor: pointer
}

.exceltbl table th,
.exceltbl table td {
    border-right: 1px solid #454d66;
    position: relative
}

.exceltbl table th:last-child,
.exceltbl table td:last-child {
    border-right: 0
}

.exceltbl table .form-control {
    border: 0;
    background: #fff;
    border-radius: 0
}

.exceltbl table .react-tel-input input[type=text],
.exceltbl table .react-tel-input input[type=tel] {
    background: #fff !important
}

.exceltbl input.error {
    background: #ffe2e7
}

.response-table table {
    margin-bottom: 0
}

.response-table table th {
    border: 0
}

.response-table table td {
    padding: 1.4rem 1rem;
    color: #3d4b72
}

.no-table-border .accordion-toggle[aria-expanded=true] td {
    border-bottom: 0
}

.no-table-border table th,
.no-table-border table td {
    border: 0
}

.no-table-border table .accordion-toggle td {
    padding: 1.2rem 1rem;
    cursor: pointer;
    border-bottom: 12px solid #f6f8fb
}

.no-table-border table td {
    padding: 0
}

.no-table-border table tbody tr {
    background: #fff
}

.no-table-border .entity-sub-tbl td,
.no-table-border .entity-sub-tbl th {
    padding: .5rem 2rem
}

.no-table-border .entity-sub-tbl .hiddenRow {
    padding: 0 !important
}

.sticky-slert {
    position: fixed;
    top: 76px;
    right: 60px;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    max-width: 300px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
    z-index: 1030;
    display: none
}

.sticky-slert .icon-with-text {
    color: #3d4b72
}

.sticky-slert .icon-with-text .bt-icon {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    background-size: contain
}

.btn {
    outline: none !important;
    border: 0;
    font-size: 13px;
    padding: 9px 40px;
    text-transform: uppercase;
    height: 36px;
    background: transparent;
    overflow: hidden;
    position: relative;
    letter-spacing: .9px;
    border-radius: 4px;
    min-width: 144px;
    cursor: pointer
}

.btn.focus,
.btn:focus,
.btn:hover {
    box-shadow: 0 2px 4px #0000003d
}

.btn_primary.focus,
.btn_primary:focus,
.btn_primary:hover {
    color: #fff
}

.btn_default.focus,
.btn_default:focus,
.btn_default:hover {
    box-shadow: none
}

.btn_primary_new {
    /*background-color: #467DFC;*/
    background-image: linear-gradient(to right, #9664FF , #467DFC);
    color: #fff
}

.btn_primary {
    /*background-color: #467DFC;*/
    background-image: linear-gradient(to right, #9664FF , #467DFC);
    color: #fff
}

.btn_secondary {
    color: #454d66 !important;
    border: 1px solid #454d66 !important
}

.btn_disabled {
    background: #aaa;
    color: #fff;
    pointer-events: none;
    cursor: not-allowed
}

.btn_lg {
    min-width: 200px
}

.btn_teritary {
    background-color: transparent;
    font-weight: 500;
    color: #454d66;
    font-size: 14px;
    letter-spacing: 0
}

.form-group {
    margin-bottom: 2rem
}

.form-group label {
    font-size: 13px;
    color: #3d4b72
}

.form-group label.error {
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    color: #ff5a5a !important
}

.error-text {
    color: #ff5a5a
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
    height: 40px !important;
    background: #f5f5f5 !important
}

.form-control {
    border-color: #e2e2e2;
    border-radius: 4px;
    background: #f5f5f5;
    font-size: 13px;
    height: 40px
}

.form-control:focus {
    border-color: #e2e2e2;
    box-shadow: none;
    background: #f5f5f5
}

.form-control.error {
    border-color: #ff5a5a
}

.form-control[disabled],
.form-control[readonly] {
    background: #f6f8fb;
    color: #3d4b72
}

a,
a:hover {
    text-decoration: none;
    color: #3d4b72
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(../images/icons/icon-select-arrow.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 98% 7px !important;
    background-size: 13px 8px;
    cursor: pointer
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.noresize {
    resize: none
}

::placeholder {
    color: #8798ad
}

::-moz-placeholder {
    color: #8798ad
}

::-webkit-input-placeholder {
    color: #8798ad
}

.container_radio {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    float: left
}

.container_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.container_radio input:checked~.checkmark {
    background-color: #eceef3
}

.container_radio input:checked~.checkmark:after {
    display: block
}

.container_radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #454d66;
    border: 1px solid #f1f1f3
}

.container_radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #eee;
    border-radius: 50%
}

.container_radio .checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.container_Checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #3d4b72
}

.container_Checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.container_Checkbox input:checked~.checkmark:after {
    display: block
}

.container_Checkbox.success input:checked~.checkmark {
    background-color: #26b789;
    border: 0
}

.container_Checkbox .checkmark:after {
    left: 1px;
    top: 1px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../images/icons/icon-checkbox-tick.svg)
}

.container_Checkbox.roundedcheckbox {
    line-height: 23px
}

.container_Checkbox.roundedcheckbox input:checked~.checkmark {
    background: #454d66;
    border: 0
}

.container_Checkbox.roundedcheckbox .checkmark {
    border-radius: 100%;
    background: transparent;
    border: 1px solid #3d4b72
}

.container_Checkbox.roundedcheckbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.container_Checkbox .checkmark {
    position: absolute;
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background: #eceef3
}

.container_Checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none
}

input[type=checkbox]:focus~label:before,
input[type=radio]:focus~label:before {
    outline: none !important;
    box-shadow: none !important
}

.login-bg {
    background: #d5d5d5;
    height: 100vh;
    padding-top: 170px
}

.login-bg .login-block {
    background: #f6f8fb;
    border-radius: 4px;
    width: 384px;
    margin: 0 auto;
    padding: 40px 30px;
    margin-bottom: 70px;
    position: relative;
    z-index: 1;
    min-height: 300px
}

.loginbg {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0
}

.terms-of-use {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    text-align: center;
    z-index: 1
}

.terms-of-use a {
    color: #fff;
    font-size: 13px
}

.otp-inputs {
    display: flex;
    margin-top: 10px
}

.otp-inputs .form-control {
    width: 14%;
    float: left;
    margin-right: 10px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d5d5d5;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
    padding: 0
}

.otp-inputs .form-control:last-child {
    margin-right: 0
}

.otp-inputs .form-control:focus {
    border-bottom: 1px solid #d5d5d5
}

.mainlogo {
    text-align: center;
    margin-bottom: 15px
}

.mainlogo img {
    max-width: 100%;
    height: 56px
}

.forgetlink {
    font-size: 13px;
    font-weight: 500;
    color: #454d66 !important
}

.navbar {
    padding: 0 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .02);
    background: #fff;
    justify-content: space-between;
    height: 56px
}

.navbar .notification-dropdown {
    width: 30rem;
    padding: 1.5rem 2rem;
    border-radius: 8px
}

.navbar .notification-dropdown ul li {
    border-top: 1px solid #f1f1f3;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer
}

.navbar .dropdown-header {
    font-size: 16px;
    color: #454d66;
    padding: 0;
    margin-bottom: 15px
}

.navbar .navbar-brand {
    padding: 0;
    margin: 0
}

.navbar .navbar-brand img {
    height: 35px
}

.navbar .navbar-nav .nav-item {
    text-transform: capitalize;
    display: flex;
    height: 56px;
    align-items: center;
    width: auto;
    padding: 0 30px
}

.navbar .navbar-nav .nav-item:nth-child(3) {
    margin-right: 0
}

.navbar .navbar-nav .nav-item.active {
    background: #454d66
}

.navbar .navbar-nav .nav-item.active a {
    color: #fff
}

.navbar .navbar-nav .nav-item:last-child {
    margin-right: 0;
    padding-right: 0
}

.navbar .navbar-nav .nav-item.mobile-menu {
    display: none
}

.navbar .navbar-toggler {
    border: 0;
    padding: 0
}

.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none
}

.navbar .navbar-toggler .toggle-bar,
.navbar .navbar-toggler .toggle-bar:before,
.navbar .navbar-toggler .toggle-bar:after {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.navbar .navbar-toggler[aria-expanded=true] .toggle-wrap .toggle-bar {
    border-top: 6px solid transparent
}

.navbar .navbar-toggler[aria-expanded=true] .toggle-wrap .toggle-bar:before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.navbar .navbar-toggler[aria-expanded=true] .toggle-wrap .toggle-bar:after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 7px
}

.navbar .user-navbar {
    color: #3d4b72
}

.navbar .btn-group a:not([href]):not([tabindex]) {
    color: #3d4b72;
    cursor: pointer
}

.navbar .body2.dropdown.nav-item {
    padding: 0
}

.dropdown-menu {
    left: inherit;
    right: 0;
    padding: 1rem;
    margin: 0;
    border: 0;
    margin-top: 10px;
    border-radius: 0;
    box-shadow: 0 2px 4px #0000000d
}

.dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 1rem 3rem;
    color: #454d66;
    font-weight: 500
}

.drop-link .dropdown-item:last-child:hover {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.navbar-collapse {
    justify-content: flex-end
}

.navbar-collapse.open {
    display: block;
    opacity: 1
}

.dropdown-item.active,
.dropdown-item:active {
    background: #f3f4f6
}

body {
    background: #f5f5f5
}

.all-notification {
    cursor: pointer;
    text-align: center;
    border-top: 1px solid #f1f1f3;
    padding-top: 15px
}

.toggle-wrap {
    padding: 10px;
    position: relative;
    cursor: pointer;
    float: left;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.toggle-wrap .toggle-bar {
    width: 38px;
    margin: 0;
    position: relative;
    border-top: 4px solid #454d66;
    display: block
}

.toggle-wrap .toggle-bar:before,
.toggle-wrap .toggle-bar:after {
    content: "";
    display: block;
    background: #454d66;
    height: 4px;
    width: 38px;
    position: absolute;
    top: -13px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform-origin: 13%;
    -webkit-transform-origin: 13%;
    transform-origin: 13%
}

.toggle-wrap .toggle-bar:after {
    top: 5px
}

.product-logo {
    height: 30px;
    float: left;
    margin-right: 12px
}

.product-logo img {
    max-width: 100%;
    max-height: 100%
}

.profile-out .dropdown-menu {
    width: 350px;
    border-radius: 8px;
    margin-top: 1rem;
    padding: 0
}

.profile-out .profile-drop {
    padding: 2rem;
    border-bottom: 1px solid #f1f1f3
}

.profile-out .profile-drop img {
    height: 80px;
    width: 80px;
    border-radius: 100%
}

.profile-out .profile-drop .media-body {
    margin-top: 13px;
    margin-left: 15px
}

.profile-out .profile-drop .sub-heading2,
.profile-out .profile-drop .notification-list .notification-content .ago-time,
.notification-list .notification-content .profile-out .profile-drop .ago-time,
.profile-out .profile-drop .notification-list .notification-content .text-action a,
.notification-list .notification-content .text-action .profile-out .profile-drop a,
.profile-out .profile-drop .notification-list .notification-content .notification-text p,
.notification-list .notification-content .notification-text .profile-out .profile-drop p,
.profile-out .profile-drop .user-card .bt__img-text-group .text-wrapper .name,
.user-card .bt__img-text-group .text-wrapper .profile-out .profile-drop .name,
.profile-out .profile-drop .sticky-slert .icon-with-text,
.sticky-slert .profile-out .profile-drop .icon-with-text {
    margin-bottom: 5px
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #454d66
}

.progressbar {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 10px;
    overflow: hidden;
    counter-reset: step
}

.progressbar li {
    list-style-type: none;
    color: #5f6368;
    text-transform: uppercase
}

.progressbar li:first-child:after {
    content: none
}

.progressbar li.active:before {
    background: #26b789
}

.progressbar li.active:after {
    background: #26b789
}

.progressbar li.active.success:before {
    background: #26b789
}

.progressbar li.active .litext {
    color: #fff
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 25px;
    height: 25px;
    display: block;
    font-size: 13px;
    color: transparent;
    background: #f6f8fb;
    border-radius: 100%;
    margin: 0 auto 5px auto;
    position: relative;
    z-index: 1
}

.progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: #dad9dd;
    position: absolute;
    left: -50%;
    top: 11px;
    z-index: 0
}

.progressbar li .litext {
    text-align: center;
    color: #454d66;
    width: 25px;
    display: block;
    font-size: 13px;
    border-radius: 100%;
    margin: 0 auto 5px auto;
    position: relative;
    z-index: 1;
    top: -26px
}

.sliderarea {
    margin-top: 100px
}

.primary-color {
    color: #454d66 !important
}

.secondary-color {
    color: #26b789 !important
}

.light-white-bg {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 15px 20px 15px 20px
}

.white-bg {
    background: #fff;
    border-radius: 4px;
    padding: 15px 20px 15px 20px
}

.container-fluid {
    padding: 0 1.5rem
}

.new-case {
    margin-top: 100px
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #454d66;
    border-bottom-color: #454d66
}

.nav-tabs .nav-link {
    display: block;
    padding: .5rem 3rem;
    background: transparent !important;
    border: 0;
    border-bottom: 2px solid transparent
}

.remove-button-style,
.profile-form .change-profile-image .change-image,
.action-group .action-option ul li button {
    background-color: transparent;
    padding: 0;
    border: none
}

.no-wrap {
    white-space: nowrap !important
}

.scrollcss {
    scrollbar-width: thin
}

.scrollcss::-webkit-scrollbar-track {
    background-color: #fff
}

.scrollcss::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5
}

.scrollcss::-webkit-scrollbar-thumb {
    border-radius: 45px;
    background-color: #e5e5e5
}

.suspicious,
.failed {
    color: #fe6666
}

.yet-to-start,
.in-progress {
    color: #d5d5d5
}

.clear {
    color: #26b789
}

.insufficiency {
    color: #efa003
}

.box-shadow-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, .05)
}

.padding-card {
    padding: 25px;
    background: #fff
}

.case_tbl_user_accordion .body2 {
    color: #8798ad
}

.password-block {
    width: 330px;
    margin: 20px auto
}

.blockform {
    margin-top: 50px;
    margin-bottom: 50px
}

.companylogo {
    width: 220px
}

.companylogo img {
    height: 80px
}

.details-block {
    width: 50%;
    margin: 20px auto
}

.inner-form {
    margin: 40px auto;
    margin-bottom: 10px;
    width: 97%;
    padding: 25px 25px 25px 25px
}

.badge {
    border-radius: 12px;
    padding: 5px 8px 5px 8px;
    min-width: 73px;
    font-size: 13px;
    font-weight: normal;
    background: #eceef3
}

.cibil-badge {
    padding: 7px 13px 5px 13px
}

.pack-group {
    border: 1px solid #f1f1f3;
    margin-bottom: 30px;
    padding: 15px 15px 15px 15px
}

.pack-group label {
    font-size: 16px
}

.iconset {
    margin: 15px auto 0;
    display: flex;
    flex-wrap: wrap
}

.iconset li {
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative
}

.iconset li:last-child {
    margin-right: 0
}

.iconset li:hover .icon-details {
    display: block
}

.entity-form {
    padding: 30px 30px 30px 30px;
    margin: 0 auto;
    width: 98%
}

.icon-details {
    display: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    padding: 15px 20px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 23px;
    width: 280px;
    background: #fff;
    z-index: 1
}

.user-search .form-group {
    width: 23%;
    float: left
}

.user-search .form-group .form-control {
    padding-right: 35px
}

.user-search img {
    position: absolute;
    right: 10px;
    top: 4px
}

.user-search .btn {
    min-width: 90px;
    padding: 9px 30px;
    float: left
}

.user-group {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px
}

.user-group .username {
    color: #3d4b72;
    font-size: 13px;
    float: left;
    margin-right: 10px
}

.user-group .username .badge {
    width: auto
}

.user-group img {
    float: right;
    cursor: pointer;
    width: 20px
}

.tb__side-pop {
    position: fixed;
    top: 0;
    z-index: 1031;
    width: 500px;
    right: 0;
    background-color: #fff;
    height: 100%;
    -webkit-transition: width .1s
}

.create-new-pack-overlay {
    position: fixed;
    background: #32384930;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1030
}

.sidepop_header {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 20px;
    padding-bottom: 0
}

.sidepop_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 20px;
    padding-bottom: 0;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, .08);
    background: #fff
}

.sidepop_body {
    margin-top: 70px;
    overflow: auto;
    height: 80vh
}

#pack-accordion .card {
    border: 0;
    margin-bottom: 15px
}

.pack-accordion-head[aria-expanded=false] {
    background: transparent
}

.pack-accordion-head[data-toggle=collapse] {
    cursor: pointer
}

.pack-accordion-head {
    padding: 10px 20px
}

#pack-accordion .card .card-body,
.pack-accordion-head[aria-expanded=true] {
    background: #fff
}

.pack-accordion-head[aria-expanded=true] .component-icons {
    display: none
}

#pack-accordion .pack-accordion-head>img:first-child {
    margin-right: 20px
}

.panel-lists~.sub-heading2,
.notification-list .notification-content .panel-lists~.ago-time,
.notification-list .notification-content .text-action .panel-lists~a,
.notification-list .notification-content .notification-text .panel-lists~p,
.user-card .bt__img-text-group .text-wrapper .panel-lists~.name,
.sticky-slert .panel-lists~.icon-with-text {
    padding-left: 50px
}

.panel-lists {
    padding-left: 50px
}

.panel-lists .increment-employments {
    background: transparent;
    cursor: default;
    display: flex;
    align-items: center;
    margin-top: -10px
}

.panel-lists .increment-employments .form-group {
    display: flex;
    align-items: center
}

.panel-lists .increment-employments .form-group .form-control {
    width: 40px;
    text-align: center;
    font-size: 14px
}

.panel-lists li {
    background: #fff;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer
}

.panel-lists li.active {
    background: #26b789
}

.panel-lists li.active span {
    color: #fff
}

.panel-lists li.active .container_Checkbox .checkmark:after {
    background-image: url(../images/icons/icon-checkbox-bluetick.svg);
    background-size: contain;
    transform: rotate(0deg);
    border: 0 !important;
    left: 3px;
    top: 4px;
    width: 11px;
    height: 10px
}

.panel-lists li.active .container_Checkbox input:checked~.checkmark {
    background: #fff
}

.form-col {
    padding: 0 10px
}

.radiolbl {
    line-height: 23px
}

.sidepop_body .user-search .form-group {
    width: 74%
}

.sidepop_body .user-search img {
    top: 30px
}

.sidepop_body .user-search .btn {
    float: right;
    margin-top: 24px
}

.accordion-like-div .select-arrow {
    float: right;
    margin-top: 7px;
    margin-left: 15px;
    cursor: pointer;
    transition: all .2s linear
}

.accordion-like-div .accordion-set {
    display: none
}

.accordion-like-div.show .accordion-set {
    display: block
}

.accordion-like-div.show .select-arrow {
    transform: rotate(-90deg)
}

.inp-click {
    height: 32px;
    width: 30px;
    background: #26b789;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px
}

.inp-click.minus {
    margin-left: 20px
}

.two-columns {
    display: flex;
    flex-wrap: wrap
}

.two-columns li {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    margin-right: 10px
}

.case-card {
    margin-top: 80px;
    padding: 50px
}

.case-card-fill {
    margin-top: 5rem
}

.right-graph-links {
    float: right
}

.right-graph-links li {
    font-size: 13px;
    display: inline-block;
    margin-right: 20px;
    color: #3d4b72;
    cursor: pointer
}

.right-graph-links li.active {
    color: #454d66
}

.right-graph-links li:last-child {
    margin-right: 0
}

.background-graph img {
    width: 100%
}

.dashboard-cards {
    display: flex;
    margin-top: 30px
}

.dashboard-cards .card {
    flex: 1;
    border-color: transparent;
    border-radius: 0;
    align-items: center;
    transition: .05s all linear
}

.dashboard-cards .card:after {
    position: absolute;
    content: "";
    border-right: 1px solid #e7e7e7;
    width: 1px;
    height: 50%;
    right: 0;
    top: 25%
}

.dashboard-cards .card:last-child:after {
    border-right: 0
}

.dashboard-cards .card .media {
    padding: 30px 0
}

.dashboard-cards .card .media .clr {
    display: none
}

.dashboard-cards .card .media-body div span {
    margin-left: 4px
}

.dashboard-cards .card:hover {
    transform: scale(1);
    border-radius: 4px;
    position: inherit;
    z-index: 1;
    flex: 1.8 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff
}

.dashboard-cards .card:hover .link-div {
    display: block
}

.dashboard-cards .card .round-icons {
    margin-right: 20px;
    margin-top: 4px
}

.dashboard-cards .card .round-icons img {
    height: 30px
}

.dashboard-cards .card .media-body span {
    color: #3d4b72;
    font-size: 13px
}

.dashboard-cards .card .result-next-media img {
    height: 12px
}

.dashboard-cards .card .beyond-dat span {
    font-size: 10px;
    color: red
}

.link-div {
    display: none;
    position: absolute;
    width: 100.6%;
    left: -1px;
    top: 115px;
    background: #fff;
    z-index: 99;
    padding: 1rem 2rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.link-div.btn {
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.link-div li {
    display: block;
    width: 100%
}

.link-div li h5,
.link-div li .social-intelligence-wrapper .personality-detail .card-content .big-text,
.social-intelligence-wrapper .personality-detail .card-content .link-div li .big-text {
    width: 50px;
    float: left;
    font-size: 20px
}

.link-div li .body2 {
    float: left;
    font-size: 13px
}

.link-div .border-div {
    border-top: 1px solid #f1f1f3;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px
}

.view-all {
    text-transform: uppercase;
    cursor: pointer;
    float: right;
    margin-left: auto;
    font-size: 13px;
    text-decoration: underline
}

.batch_ul {
    display: flex;
    border-bottom: 1px solid #f1f1f3;
    justify-content: center;
    margin: 30px 0
}

.batch_ul li {
    color: rgba(0, 0, 0, .5);
    cursor: pointer;
    text-transform: capitalize;
    margin-right: 25px;
    border-bottom: 3px solid transparent;
    padding: 0 15px 10px
}

.batch_ul li:last-child {
    margin-right: 0
}

.batch_ul li.active {
    color: #454d66;
    border-bottom-color: #454d66
}

.color_sorting {
    display: flex;
    justify-content: center
}

.color_sorting .circle-batch {
    height: 13px;
    width: 13px;
    background: #ccc;
    border-radius: 100%;
    margin-top: 2px
}

.color_sorting.color-success .circle-batch {
    background: #26b789
}

.color_sorting.color-warning .circle-batch {
    background: #efa003
}

.color_sorting.color-danger .circle-batch {
    background: #fe6666
}

.color_sorting.color-default .circle-batch {
    background: #3d4b72
}

.color_sorting svg {
    fill: #d5d5d5;
    cursor: pointer
}

.cases-set {
    padding: 30px;
    background: #fff
}

.cases-set .left-form-elements {
    display: flex;
    float: left
}

.cases-set .left-form-elements .form-control {
    min-width: 150px
}

.cases-set .left-form-elements .form-group {
    width: 180px;
    margin-right: 15px
}

.cases-set .left-form-elements .form-group:last-child {
    margin-right: 0
}

.cases-set .right-form-elements {
    float: right
}

.cases-set .right-form-elements .form-group {
    width: 180px
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.tag-list.adddoc li {
    border: 1px solid #eceef3;
    background: transparent;
    cursor: pointer
}

.tag-list li {
    background: #eceef3;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 10px;
    font-size: 13px;
    margin-bottom: 15px
}

.tag-list li span {
    float: left;
    margin-right: 10px;
    color: #3d4b72;
    font-size: 13px
}

.tag-list li img {
    float: right;
    width: 15px;
    cursor: pointer;
    margin-top: 2px
}

.document-tag-list .adddoc {
    border: 1px solid #eceef3;
    background: transparent;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 13px
}

.document-tag-list li {
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0
}

.document-tag-list li .list-tags {
    background: #eceef3;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 10px;
    font-size: 13px;
    float: left
}

.document-tag-list li img {
    float: left;
    width: 15px;
    cursor: pointer;
    margin-top: 8px
}

.completed {
    color: #26b789 !important
}

.danger {
    color: #fe6666 !important
}

.inprogress {
    color: #efa003
}

.title-hover {
    position: relative
}

.title-hover:hover .icon-details {
    display: block
}

.approve-card {
    background: #f6f8fb;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px
}

#view-approval {
    width: 450px
}

.upload-div {
    display: flex;
    width: 100%;
    height: 120px;
    background: #f8f8f8;
    border: 2px dashed #d5d5d5;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #3d4b72;
    font-size: 16px;
    cursor: pointer;
    position: relative
}

.upload-div input[type=file] {
    position: absolute;
    height: 100%;
    width: 100%;
    outline: none !important;
    opacity: 0;
    cursor: pointer
}

.case_tbl_user_accordion .card {
    border: 0
}

.case_tbl_user_accordion .card-body,
.case_tbl_user_accordion .pack-accordion-head[aria-expanded=true] {
    background: #fff
}

#case-tbl-user .sidepop_body {
    margin-top: 0
}

.report-text {
    color: #26b789;
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
    background: #f6f8fb;
    font-weight: 500
}

.panel-card {
    padding: 0 10px;
    border-bottom: 1px solid #f1f1f3;
    padding-bottom: 20px;
    margin-bottom: 20px
}

.panel-card:last-child {
    margin-bottom: 0;
    border-bottom: 0
}

.file-btn {
    width: 230px;
    background: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    position: relative;
    margin-top: 15px;
    float: left
}

.file-btn.error-upload {
    background: #ff98a8
}

.file-btn.error-upload .body2 {
    color: #fff
}

.file-btn svg {
    position: absolute;
    right: 3px;
    border-radius: 100%;
    padding: 7px;
    top: 2px;
    height: 90%;
    width: 35px;
    cursor: pointer
}

.download-btn svg,
.upload-btn.uploading svg {
    background: #454d66;
    fill: #fff
}

.upload-btn svg {
    background: #d5d5d5;
    fill: #454d66;
    transform: rotate(0deg)
}

.error-upload svg {
    background: #fe6666;
    fill: #fff
}

.file-row .processdiv {
    float: left;
    margin-top: 23px;
    margin-left: 20px
}

.file-row .error-text,
.file-row .completed {
    font-weight: 500;
    margin-top: 25px;
    margin-left: 20px
}

.file-name {
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
    text-overflow: ellipsis
}

.residence_view {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    z-index: 1030;
    width: calc(100% - 501px);
    padding: 20px 30px
}

.residence-header img {
    float: right;
    cursor: pointer
}

.large-map iframe {
    width: 100%;
    height: 500px
}

.upload-content1 .upload-div {
    height: 220px
}

.modal-header .close {
    cursor: pointer
}

.entity-card {
    margin-top: 70px
}

.entity-card .search-filter {
    position: relative;
    width: 250px
}

.entity-card .search-filter .form-control {
    padding: 5px 15px
}

.component-icons img {
    height: 16px;
    margin-right: 5px
}

.create-permission-modal .card {
    border: 0;
    padding: 0 10px
}

.create-permission-modal .panel-lists {
    padding: 0 15px
}

.create-permission-modal .panel-lists li {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08)
}

.create-permission-modal .panel-lists li .subheading2 {
    color: #454d66
}

.create-permission-modal .panel-lists li p {
    color: #3d4b72
}

.create-permission-modal .panel-lists li.active {
    box-shadow: none
}

.create-permission-modal .panel-lists li.active .subheading2,
.create-permission-modal .panel-lists li.active p {
    color: #fff
}

.create-permission-modal .panel-lists p {
    font-size: 13px
}

.agreement .body1,
.agreement .social-intelligence-wrapper .persona-detail p,
.social-intelligence-wrapper .persona-detail .agreement p,
.agreement .social-intelligence-wrapper .personality-detail .card-content .small-text,
.social-intelligence-wrapper .personality-detail .card-content .agreement .small-text {
    word-break: break-all
}

.bottom_alert {
    position: fixed;
    bottom: -70px;
    width: 96%;
    left: 2%;
    padding: 15px;
    color: #fff;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: all .2s linear
}

.bottom_alert p {
    margin: 0
}

.bottom_alert.alert_success {
    background: #26b789
}

.bottom_alert.alert_danger {
    background: #fe6666
}

.bottom_alert.alert_default {
    background: #2e384d
}

.bottom_alert svg {
    position: absolute;
    right: 20px;
    top: 10px;
    fill: #fff;
    cursor: pointer
}

.title-hover {
    margin-right: 20px;
    float: left
}

.title-hover:last-child {
    margin-right: 0
}

.icon-status {
    display: block;
    height: 24px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat
}

.check-card {
    background: #fff;
    padding: 35px 25px
}

.check-card li {
    font-weight: 500
}

.check-card .progress {
    border-radius: 0;
    margin-bottom: 20px
}

.check-card .progress .progress-danger {
    background: #fe6666
}

.check-card .progress .progress-inprogress {
    background: #efa003
}

.check-card .progress .progress-success {
    background: #26b789
}

.check-card .right-set-legend {
    display: flex;
    float: right
}

.check-card .right-set-legend li {
    font-size: 13px;
    color: #454d66;
    position: relative;
    margin-right: 30px;
    padding-left: 18px
}

.check-card .right-set-legend li:last-child {
    margin-right: 0
}

.check-card .right-set-legend li:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    top: 3px;
    left: 0
}

.check-card .right-set-legend li.progress-danger:before {
    background: #fe6666
}

.check-card .right-set-legend li.progress-inprogress:before {
    background: #efa003
}

.check-card .right-set-legend li.progress-success:before {
    background: #26b789
}

.candidate-card {
    background: #fff;
    border-radius: 8px;
    padding: 30px 35px;
    margin-bottom: 50px
}

.seperate-block {
    background: #f6f8fb;
    margin-bottom: 20px;
    padding: 20px
}

.less-font-weight .sub-heading2,
.less-font-weight .notification-list .notification-content .ago-time,
.notification-list .notification-content .less-font-weight .ago-time,
.less-font-weight .notification-list .notification-content .text-action a,
.notification-list .notification-content .text-action .less-font-weight a,
.less-font-weight .notification-list .notification-content .notification-text p,
.notification-list .notification-content .notification-text .less-font-weight p,
.less-font-weight .user-card .bt__img-text-group .text-wrapper .name,
.user-card .bt__img-text-group .text-wrapper .less-font-weight .name,
.less-font-weight .sticky-slert .icon-with-text,
.sticky-slert .less-font-weight .icon-with-text {
    font-weight: 300;
    font-size: 13px
}

.details_dot {
    margin-top: 2px;
    height: 18px;
    width: 18px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: contain
}

.round-tick {
    background-image: url(../images/icons/round-tick.svg)
}

.round-border {
    background-image: url(../images/icons/round-border.svg)
}

.report-greentick {
    background-image: url(../images/component-icons/report-greentick.svg)
}

.slick-card .case-col {
    border-right: 1px solid #ccc
}

.slick-card .case-col p {
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0
}

.toggle-label {
    position: relative;
    display: block;
    height: 40px;
    width: 230px;
    margin: 0;
    border-radius: 20px;
    background: #ebebeb
}

.toggle-label input[type=checkbox] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%
}

.toggle-label input[type=checkbox]+.back {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .2s linear
}

.toggle-label input[type=checkbox]:checked+.back .toggle {
    right: 0
}

.toggle-label input[type=checkbox]+.back .toggle {
    display: block;
    position: absolute;
    content: " ";
    background: #fff;
    width: 50%;
    height: 100%;
    transition: all .2s linear;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16)
}

.toggle-label .label {
    display: block;
    position: absolute;
    width: 50%;
    color: #454d66;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer
}

.toggle-label .label.on {
    left: 0
}

.toggle-label .label.off {
    right: 0
}

.disable-select {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.manual-details2 {
    width: 80%
}

.manual-details2 .upload-div {
    margin: 40px 0
}

.manual-details2 .upload-docs li {
    margin-bottom: 20px
}

.manual-details2 .upload-docs li label {
    color: #454d66;
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    cursor: default;
    margin-bottom: 0
}

.manual-details2 .upload-docs li label,
.manual-details2 .upload-docs li .body2 {
    padding-left: 27px
}

.processdiv {
    text-align: center
}

.processdiv span {
    font-size: 20px;
    color: #3d4b72;
    margin-right: 10px
}

.large-processdiv {
    padding: 150px 0
}

.loading {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg)
    }

    to {
        -ms-transform: rotate(360deg)
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg)
    }

    to {
        -moz-transform: rotate(360deg)
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.error-docs {
    padding: 20px 25px;
    background: #fff;
    margin: 10px 0 25px 0
}

.error-docs .error {
    font-weight: 500
}

.error-docs .tag-list li {
    padding: 8px 12px;
    font-size: 13px;
    cursor: pointer
}

.manual-details3 {
    width: 80%
}

.manual-details3 .card {
    border: 0;
    background: transparent;
    border-bottom: 1px solid #f1f1f3
}

.manual-details3 h6 {
    float: left;
    margin-bottom: 0
}

.manual-details3 .pack-accordion-head {
    padding: 20px
}

.manual-details3 .pack-accordion-head[aria-expanded=true] {
    background: #fff
}

.manual-details3 .card-body {
    background: #fff
}

.manual-details3 .details-form {
    padding: 0 6px;
    margin-top: -15px
}

.uploading-progress {
    margin-bottom: 20px
}

.uploading-progress li {
    padding: 10px 20px;
    position: relative;
    overflow: hidden
}

.uploading-progress li span,
.uploading-progress li img {
    position: relative;
    z-index: 1;
    color: #454d66
}

.uploading-progress li .progress-bar {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background: #58e898;
    opacity: .4
}

.case_initiation_accordion .card {
    background: #fff;
    border-radius: 4px;
    border: 0;
    margin-bottom: 16px
}

.case_initiation_accordion .pack-accordion-head {
    padding: 20px
}

.response-card .media-body {
    display: flex;
    align-items: center
}

.response-card .media-body .body1,
.response-card .media-body .social-intelligence-wrapper .persona-detail p,
.social-intelligence-wrapper .persona-detail .response-card .media-body p,
.response-card .media-body .social-intelligence-wrapper .personality-detail .card-content .small-text,
.social-intelligence-wrapper .personality-detail .card-content .response-card .media-body .small-text {
    margin-left: 20px;
    margin-top: 10px
}

.refresh-icon {
    width: 17px;
    margin-top: 5px;
    cursor: pointer
}

.accordian-body {
    padding: 20px
}

.accordian-body .media-body {
    margin-bottom: 30px
}

.dropdown_btn {
    padding: 9px 20px
}

.user-card-footer {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin: 0
}

.case-initiaion-cards .user-card {
    background: #f6f8fb;
    margin-bottom: 30px
}

.case-initiaion-cards .user-card:hover {
    background: #fff
}

.right-extend-time {
    display: flex;
    float: right
}

.right-extend-time .form-control {
    margin-top: 5px;
    width: 37px;
    margin-right: 10px
}

.right-extend-time label {
    margin: 10px 10px 0 0
}

.top-card-set {
    background: #fff;
    padding: 20px 30px 0 0
}

.top-card-set .row {
    margin: 0;
    padding: 15px 0 0 25px
}

.bd-highlight {
    background: #f6f8fb
}

.single-row-set {
    padding: 20px 30px
}

.single-row-set .form-group {
    margin: 0;
    margin-right: 50px
}

.single-row-set .form-group:last-child {
    margin-right: 0
}

.case-card-fill2 {
    margin-top: 2.6rem
}

.bottom_buttons {
    background: #f6f8fb;
    padding: 15px
}

.basic-details-modal .modal-dialog {
    max-width: 600px
}

.basic-details-modal .modal-header,
.basic-details-modal .modal-body {
    padding: 3rem 3rem 0
}

.modal-content {
    border-radius: 10px
}

.modal-header,
.modal-body {
    padding: 2rem 2rem 1rem;
    border: 0
}

.modal-footer {
    border: 0
}

.add-document-modal .modal-dialog {
    max-width: 80%
}

.reset-password .modal-content,
.yes-or-no-modal .modal-content {
    border-radius: 0;
    background: #f6f8fb
}

.reset-password .close,
.yes-or-no-modal .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99
}

.reset-password .modal-body,
.yes-or-no-modal .modal-body {
    padding: 30px 45px
}

.reset-password .modal-body h6,
.yes-or-no-modal .modal-body h6 {
    margin-bottom: 25px;
    margin-top: 15px
}

.reset-password .modal-body img,
.yes-or-no-modal .modal-body img {
    float: left;
    margin-right: 15px
}

.reset-password .modal-footer,
.yes-or-no-modal .modal-footer {
    display: block;
    padding-bottom: 30px
}

.after-style,
.top-bar::after,
.user-card::after,
.bt__img-text-group::after,
.card-group:after,
.bt_row::after,
.admin-row::after,
.admin-main-wrapper::after {
    content: "";
    display: table;
    clear: both
}

.tag {
    background-color: #eceef3;
    border-radius: 20px;
    padding: 8px 15px;
    display: inline-block
}

.tag.tag-success {
    color: #26b789
}

.admin-main-wrapper {
    padding: 20px;
    margin-top: 57px;
    position: relative
}

.admin-row .left-side,
.admin-row .right-side {
    float: left;
    display: block;
    position: relative;
    min-height: 1px
}

.admin-row .left-side {
    width: 215px
}

.admin-row .right-side {
    width: calc(100% - 215px);
    padding: 30px;
    background-color: #f6f8fb
}

.admin-row .sticky-siderbar {
    position: -webkit-sticky;
    position: sticky;
    top: 77px;
    bottom: 0;
    z-index: 2;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.bt_row {
    margin-left: -10px;
    margin-right: -10px
}

.bt_row .bt_col {
    float: left;
    position: relative;
    display: block;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.card-group {
    margin-left: -10px;
    margin-right: -10px
}

.card-group .card-list {
    float: left;
    display: block;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.bt__img-text-group .img-wrapper,
.bt__img-text-group .text-wrapper {
    float: left;
    display: block;
    position: relative;
    min-height: 1px
}

.bt__img-text-group .img-wrapper {
    width: 60px;
    height: 60px;
    overflow: hidden
}

.bt__img-text-group .img-wrapper img {
    min-width: 100%;
    max-height: 100%;
    max-width: initial
}

.bt__img-text-group .text-wrapper {
    width: calc(100% - 60px);
    padding-left: 10px
}

.bt__img-text-group .text-wrapper p {
    margin-bottom: 0
}

.bt__label-group .label {
    color: #454d66;
    float: left;
    display: block;
    position: relative;
    min-height: 1px;
    padding: 7px 15px;
    border-radius: 15px;
    background-color: #f6f8fb;
    margin-right: 15px;
    margin-bottom: 15px
}

.user-card {
    padding: 20px;
    display: block;
    position: relative;
    background-color: #fff
}

.user-card .bt__img-text-group {
    margin-bottom: 15px
}

.user-card .bt__img-text-group .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden
}

.user-card .bt__img-text-group .img-wrapper img {
    min-width: 100%;
    max-height: 100%;
    max-width: initial
}

.user-card .bt__img-text-group .text-wrapper {
    width: calc(100% - 40px)
}

.user-card .bt__img-text-group .text-wrapper .user-name {
    color: #8798ad
}

.user-card .action-group {
    position: absolute;
    top: 20px;
    right: 20px
}

.action-group {
    position: relative;
    display: block
}

.action-group .action-btn {
    padding: 0;
    background-color: transparent;
    border: none
}

.action-group .action-btn:focus {
    outline: none
}

.action-group .action-option {
    display: none;
    position: absolute;
    right: 0;
    box-shadow: 0 2px 10px #00000027;
    border-radius: 4px;
    background-color: #fff;
    min-width: 160px
}

.action-group .action-option.active {
    display: block
}

.action-group .action-option ul {
    padding: 5px 0
}

.action-group .action-option ul li {
    color: #3d4b72;
    font-weight: 400;
    line-height: 14px;
    padding: 10px 15px;
    cursor: pointer
}

.action-group .action-option ul li button {
    color: #3d4b72
}

.action-group .action-option ul li button:focus {
    outline: none
}

.added-label-group .label-list {
    padding: 8px 15px;
    position: relative;
    background-color: #f6f8fb;
    margin-bottom: 10px
}

.added-label-group .label-list .label {
    color: #3d4b72;
    line-height: 14px;
    margin-bottom: 0
}

.added-label-group .label-list .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    opacity: .2;
    background-color: transparent;
    padding: 0;
    border: none
}

.admin-side-menu ul {
    padding-right: 20px
}

.admin-side-menu a {
    color: #454d66;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    display: block;
    padding: 10px 15px;
    position: relative
}

.admin-side-menu a .bt-icon {
    width: 24px;
    float: left;
    margin-top: -3px;
    margin-right: 10px
}

.admin-side-menu a .bt-icon.bt-company-settings {
    margin-top: 1px
}

.admin-side-menu a.active,
.admin-side-menu a:hover {
    background-color: #fff
}

.admin-side-menu a .notification-count {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #3d4b72;
    color: #fff;
    font-size: 10px;
    right: 10px;
    top: 9px;
    padding-top: 2px;
    text-align: center
}

.slide-toggle {
    display: none
}

.close-sidemenu {
    display: none;
    float: right;
    margin: 10px 35px 20px 0;
    cursor: pointer
}

.search-filter {
    position: relative
}

.search-filter .form-control {
    height: 40px;
    padding: 10px 15px;
    background-color: #fff
}

.search-filter .bt-search {
    position: absolute;
    top: 15px;
    right: 13px
}

.admin-main-wrapper .users-wrapper .card-list {
    width: 50%;
    margin-bottom: 20px
}

.admin-main-wrapper .added-label-group {
    margin-bottom: 20px
}

.admin-main-wrapper .side-show-user {
    padding-left: 20px;
    padding-right: 20px
}

.side-show-user .sidepop_body {
    margin-top: 90px;
    padding-left: 20px;
    padding-right: 20px
}

.side-show-user .tb__pop-close {
    position: absolute;
    top: 20px;
    right: 20px
}

.side-show-user .bt__img-text-group .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.side-show-user .bt__img-text-group .text-wrapper {
    width: calc(100% - 40px)
}

.side-show-user .bt__img-text-group .text-wrapper .name {
    padding-top: 10px
}

.side-show-user .user-detail .form-group {
    margin-bottom: 1rem
}

.side-show-user .user-detail .form-group label {
    display: block
}

.reset-password .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99
}

.reset-password .modal-body {
    padding: 30px
}

.reset-password .modal-footer {
    display: block
}

.edit-user-image {
    padding: 0 25px;
    margin-bottom: 15px
}

.edit-user-image .img-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden
}

.edit-user-image .img-wrapper img {
    max-height: 100%;
    max-width: initial
}

.default-user {
    background: #f6f8fb;
    padding: 20px;
    text-align: center;
    width: 130px;
    border-radius: 100%
}

.round-text {
    float: left;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 15px
}

.yes-or-no-modal .modal-body .warning-header svg {
    float: left;
    margin-right: 15px;
    fill: #fe6666
}

.yes-or-no-modal .modal-body .warning-header h6 {
    float: left;
    margin-top: 1px
}

.yes-or-no-modal .modal-body .warning-header.success svg {
    fill: #26b789
}

.round-image {
    width: 44px;
    border-radius: 100%;
    float: left
}

.round-image img {
    border-radius: 100%;
    height: 45px
}

.on-plainbg {
    background: #f6f8fb
}

.notification-wrapper {
    margin-top: 20px
}

.notification-list {
    background-color: #fff;
    padding: 10px 15px;
    margin-bottom: 10px;
    min-height: 57px
}

.notification-list .notification-content .bt_col1 {
    width: 50%
}

.notification-list .notification-content .bt_col2 {
    width: calc(50% - 120px)
}

.notification-list .notification-content .bt_col3 {
    width: 120px
}

.notification-list .notification-content .notification-text p {
    margin-bottom: 0;
    line-height: 35px
}

.notification-list .notification-content .notification-text p .tag {
    display: inline-block;
    margin-left: 10px;
    line-height: initial
}

.notification-list .notification-content .text-action {
    padding-top: 6px
}

.notification-list .notification-content .text-action a {
    color: #26b789;
    text-decoration: underline
}

.notification-list .notification-content .ago-time {
    color: #3d4b72;
    padding-top: 6px;
    margin-bottom: 0
}

.notification-list .notification-content .text-desc {
    color: #3d4b72;
    line-height: 20px
}

.profile-form .change-profile-image {
    margin-top: 15px;
    margin-bottom: 15px
}

.profile-form .change-profile-image .img-wrapper {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    position: relative
}

.profile-form .change-profile-image .change-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, .6);
    padding: 8px 0
}

.profile-form .change-profile-image .change-image:focus {
    outline: none
}

.profile-form .change-profile-image .change-image span {
    color: #bdbdbd;
    text-transform: uppercase
}

.profile-form .change-profile-image img {
    max-width: inherit;
    min-width: 100%;
    max-height: 100%
}

.profile-form label {
    color: #616161
}

.profile-form textarea {
    height: 80px
}

.company-wrapper .profile-form .change-profile-image {
    max-width: 200px
}

.company-wrapper .profile-form .change-profile-image label {
    color: #616161
}

.company-wrapper .profile-form .change-profile-image .img-wrapper {
    width: auto;
    height: auto;
    border-radius: 0;
    overflow: visible
}

.company-wrapper .profile-form .change-profile-image .img-wrapper img {
    max-width: 100%;
    min-width: initial
}

.company-wrapper .profile-form .change-profile-image .change-image {
    position: relative;
    background-color: transparent;
    padding: 0;
    float: right;
    font-weight: 700;
    width: auto
}

.social-intelligence-wrapper.social-world {
    background-image: url(../images/social-intelligence-bottom.png);
    background-position: bottom center;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 70px
}

.social-intelligence-wrapper .search-wrapper h4 {
    font-weight: 400;
    margin-bottom: 20px
}

.social-intelligence-wrapper .search-wrapper p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px
}

.social-intelligence-wrapper .search-wrapper p.small-text {
    color: #707070;
    font-size: 13px;
    margin-top: 15px
}

.social-intelligence-wrapper .search-wrapper .form-group {
    position: relative;
    width: 450px;
    margin: 40px auto 0 auto
}

.social-intelligence-wrapper .search-wrapper .form-group .form-control {
    background-color: #fff;
    height: 48px;
    border-radius: 8px
}

.social-intelligence-wrapper .search-wrapper .form-group .bt-icon {
    position: absolute;
    top: 17px;
    right: 15px
}

.social-intelligence-wrapper .search-top {
    max-width: 1000px;
    margin: 0 auto
}

.social-intelligence-wrapper .search-top .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px auto
}

.social-intelligence-wrapper .search-top .profile-image img {
    max-height: 100%;
    min-width: 100%;
    max-width: inherit
}

.social-intelligence-wrapper .search-top h4 {
    margin-bottom: 20px
}

.social-intelligence-wrapper .search-top p {
    color: #707070;
    font-size: 16px;
    line-height: 25px
}

.social-intelligence-wrapper .search-top .small-text {
    color: #343434;
    font-weight: 700;
    margin-bottom: 10px
}

.social-intelligence-wrapper .search-top .small-line {
    height: 2px;
    width: 80px;
    background-color: #b1b1b1;
    display: inline-block;
    margin-bottom: 15px
}

.social-intelligence-wrapper .top-detail {
    margin-top: 50px
}

.social-intelligence-wrapper .top-detail .bt__img-text-group {
    text-align: left
}

.social-intelligence-wrapper .top-detail .bt__img-text-group .text-wrapper .label-value {
    color: #343434;
    font-size: 20px
}

.social-intelligence-wrapper .social-action {
    text-align: center;
    margin-top: 80px
}

.social-intelligence-wrapper .social-action li {
    display: inline-block;
    margin-right: 30px
}

.social-intelligence-wrapper .social-action li:last-child {
    margin-right: 0
}

.social-intelligence-wrapper .personality-detail {
    margin-top: 50px
}

.social-intelligence-wrapper .personality-detail h5,
.social-intelligence-wrapper .personality-detail .card-content .big-text {
    margin-bottom: 20px
}

.social-intelligence-wrapper .personality-detail .bt_col {
    margin-bottom: 20px
}

.social-intelligence-wrapper .personality-detail .bt_row .bt_col {
    width: 25%
}

.social-intelligence-wrapper .personality-detail .bt_row-1 .bt_col {
    width: 50%
}

.social-intelligence-wrapper .personality-detail .bt_row-1 .bt_col .big-text {
    margin-bottom: 20px
}

.social-intelligence-wrapper .personality-detail .bt_row-2 .bt_col {
    width: 100%
}

.social-intelligence-wrapper .personality-detail .card-content {
    background-color: #fff;
    padding: 30px 20px
}

.social-intelligence-wrapper .personality-detail .card-content .big-text {
    margin-bottom: 5px
}

.social-intelligence-wrapper .persona-detail {
    margin-top: 40px
}

.social-intelligence-wrapper .persona-detail h5,
.social-intelligence-wrapper .persona-detail .personality-detail .card-content .big-text,
.social-intelligence-wrapper .personality-detail .card-content .persona-detail .big-text {
    margin-bottom: 20px
}

.social-intelligence-wrapper .persona-detail .bt_row {
    text-align: center
}

.social-intelligence-wrapper .persona-detail .bt_row .bt_col {
    width: 25%;
    display: inline-block;
    margin-bottom: 20px
}

.social-intelligence-wrapper .persona-detail .primary-text {
    margin-bottom: 5px
}

.graph-legends li {
    font-size: 14px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 2px;
    line-height: 39px;
    text-align: left
}

.graph-legends li:before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background: #fff;
    border-radius: 100%;
    left: 8px;
    top: 14px;
    z-index: 1
}

.graph-legends li:after {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    left: 2px;
    top: 8px
}

.graph-legends li.green-li:after {
    background: #58e898
}

.graph-legends li.red-li:after {
    background: #ff5a5a
}

.graph-legends li.violet-li:after {
    background: #8562ff
}

.graph-legends li.grey-li:after {
    background: #3d4b72
}

.graph-legends li span:last-child {
    margin-left: 10px
}

.flex-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.flex-cards .card-content {
    background: #fff;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
    padding: 20px 8px
}

.tree-area {
    padding: 30px
}

.bt__label-group .inprogress {
    color: #efa003
}

.badge-dot {
    float: right;
    margin-right: 10px;
    background: transparent;
    border: 1px dashed #d5d5d5;
    text-transform: uppercase;
    padding: 8px 12px 5px;
    width: auto;
    color: #d5d5d5
}

.badge-dot.active {
    border: 0;
    background: #26b789;
    color: #fff
}

.entity-area {
    background: #fff;
    padding: 30px
}

.treeview>ul {
    padding-left: 4px !important
}

.treeview ul {
    list-style: none;
    padding-left: 32px
}

.treeview ul li {
    padding: 20px 0 0 0;
    position: relative;
    color: #3d4b72
}

.treeview ul li:before {
    content: "";
    position: absolute;
    top: -23px;
    left: -31px;
    border-left: 2px dashed #b4becc;
    width: 1px;
    height: 100%
}

.treeview ul li:after {
    content: "";
    position: absolute;
    border-top: 2px dashed #b4becc;
    top: 36px;
    left: -30px;
    width: 35px
}

.treeview ul li:last-child:before {
    top: -22px;
    height: 60px
}

.treeview>ul>li:after,
.treeview>ul>li:last-child:before {
    content: unset
}

.treeview>ul>li:before {
    top: 90px;
    left: 36px
}

.treeview>ul>li:not(:last-child)>ul>li:before {
    content: unset
}

.treeview>ul>li>ul {
    padding-left: 34px
}

.treeview__level {
    padding: 7px;
    padding-left: 20px;
    display: inline-block;
    position: relative;
    z-index: 1
}

.treeview__level:before {
    content: attr(data-level);
    position: absolute;
    left: -1px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #454d66;
    color: #fff;
    font-size: 20px
}

.report-cards .white-bg {
    padding: 30px 25px
}

.timer-block {
    display: flex;
    margin-right: 5px
}

.timer-block .timer-expired {
    font-weight: 500;
    color: #2e744c
}

.timer-block .square-block {
    padding: 7px 10px;
    background: #f5f5f5;
    margin-right: 5px;
    border-radius: 4px;
    margin-top: -2px
}

.timer-block .large-span {
    font-size: 16px;
    color: #3d4b72;
    font-weight: 500
}

.timer-block .small-span {
    color: #3d4b72;
    font-size: 9px
}

.left-content {
    margin-top: 4rem;
    padding: 3rem;
    background: #f6f8fb;
    min-height: 80vh
}

.right-content {
    margin-top: 4rem;
    padding: 3rem 1rem;
    min-height: 80vh
}

.right-content .card {
    border: 0;
    background: transparent
}

.right-content .card .pack-accordion-head h6 {
    float: left;
    padding: 2rem
}

.right-content .card .pack-accordion-head img {
    margin-top: 1.5rem
}

.right-content .pack-accordion-head[aria-expanded=true] {
    background: #fff
}

.right-content .pack-accordion-head[aria-expanded=true] h6 {
    margin-bottom: 0
}

.right-content .card-body {
    background: #fff;
    padding: 0 3.5rem 2rem
}

.document-set .media-body {
    margin-bottom: 35px
}

.view-block li {
    border-bottom: 1px solid #f1f1f3;
    margin-bottom: 15px
}

.college-address {
    margin-top: -20px;
    margin-bottom: 30px
}

.mobile-nav-tabs {
    margin-top: 60px;
    display: none;
    padding-top: 20px
}

.mobile-nav-tabs li {
    width: 50%;
    text-align: center
}

.response-card .white-bg {
    margin-bottom: 20px
}

.left-half {
    background: #f6f8fb;
    padding-right: 0
}

.left-content,
.right-content {
    overflow: auto
}

.inside-case-initiation .left-content,
.inside-case-initiation .right-content {
    margin: 0
}

.labeltext {
    font-size: 13px;
    color: #3d4b72 !important;
    margin-bottom: .5rem !important
}

.previewText .image-container {
    margin-top: 10px !important
}

.initdetails .change-image {
    position: relative;
    background: transparent;
    padding: 0;
    float: right;
    font-weight: 700;
    width: auto
}

.initdetails .change-profile-image label {
    color: #616161
}

.initdetails .img-wrapper {
    width: auto;
    height: auto;
    border-radius: 0;
    overflow: visible
}

.change-profile-image {
    width: 200px
}

.transform90deg {
    transform: rotate(-90deg) !important
}

#bt-snackbar-wrapper {
    position: fixed;
    top: 80px;
    right: 60px
}

.bt-snackbar {
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    max-width: 300px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
    z-index: 1030;
    display: block;
    margin-bottom: 12px
}

.bt-snackbar .icon-with-text {
    color: #3d4b72;
    font-size: 14px;
    font-weight: 500
}

.bt-snackbar .icon-with-text .bt-icon {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    background-size: contain
}

.bt-snackbar.success .bt-icon {
    background-image: url(../images/icons/icon-success-tick.svg);
    width: 24px;
    height: 24px
}

.bottom_alert.show {
    z-index: 1000;
    bottom: 0
}

.deep-track {
    position: fixed;
    top: 0;
    z-index: 1031;
    width: calc(100% - 500px);
    left: 0;
    background-color: #fff;
    height: 100%;
    -webkit-transition: width .1s;
    padding: 24px 40px;
    margin: 10px
}

.deep-track .check-title {
    font-size: 16px;
    font-weight: 500;
    display: inline-block
}

.deep-track .close-deep-track {
    float: right
}

.deep-track .title {
    height: 40px
}

.deep-track .content {
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto
}

.case-check-details {
    margin-top: 40px
}

.smallfont .track-progress,
.tag .track-progress,
.bt__label-group .label .track-progress,
.user-card .bt__img-text-group .text-wrapper .user-name .track-progress,
.user-card p .track-progress,
.action-group .action-option ul li .track-progress,
.added-label-group .label-list .label .track-progress,
.notification-list .notification-content .text-desc .track-progress,
.profile-form .change-profile-image .change-image span .track-progress,
.company-wrapper .profile-form .change-profile-image label .track-progress {
    float: right;
    margin-top: -55px
}

.btn.track-progress {
    padding: 9px !important
}

.align-veritcal-center {
    vertical-align: middle !important
}

.no-pointer {
    cursor: default !important
}

.link_disabled {
    color: #3d4b72;
    cursor: not-allowed
}

.case-icon-wrapper {
    margin-right: 20px;
    float: left
}

.case-icon-wrapper:last-child {
    margin-right: 0;
    float: left
}

.case-icon-wrapper svg:hover~.icon-details {
    display: block
}

.bt-snackbar.danger .bt-icon {
    background-image: url(../images/icons/icon-fail-cross.svg);
    width: 24px;
    height: 24px
}

.summary-legends {
    margin-bottom: 15px;
    top: unset;
    left: unset;
    position: relative;
    text-align: right;
    margin-top: 16px
}

.summary-legends li {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    position: relative;
    color: #8798ad;
    padding-left: 24px;
    line-height: 22px
}

.summary-legends li:last-child {
    margin-right: 0
}

.summary-legends li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    height: 16px;
    width: 16px;
    border-radius: 50%
}

.summary-legends li:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 6px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: none !important
}

.summary-legends li.clear-legend:before {
    border: 2px solid #46a382;
    background: #d2e4e3
}

.summary-legends li.clear-legend:after {
    border: 1px solid #7ebeab
}

.summary-legends li.alert-legend:before {
    border: 2px solid #ec3131;
    background: #dbcecd
}

.summary-legends li.alert-legend:after {
    border: 1px solid #db706c
}

.css-yk16xz-control {
    border-radius: 0 !important;
    max-height: 33px !important;
    min-height: 25px !important
}

.css-1pahdxg-control:hover {
    border: 0 solid #fbffff !important;
    box-shadow: 0 !important
}

.upload-message {
    padding: 20px;
    background-color: #309975;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border-radius: 4px
}

.default-badge {
    display: inline;
    margin-right: 20px
}

.pack-badge {
    width: 115px !important
}

.collapse.navbar-collapse.open {
    display: block;
    opacity: 1
}

.pack-accordion-head.collapsed .right.pointer.open {
    transform: rotateZ(-180deg)
}

.disabled {
    pointer-events: none
}

.after-login-phase-1 .dropdown .nav-item a.dropdown-toggle.nav-link::after {
    content: none
}

.after-login-phase-1 .dropdown .nav-item {
    padding: 0 !important
}

.logoclass .companylogo {
    width: unset !important
}

.logoclass .sub-heading2,
.logoclass .sticky-slert .icon-with-text,
.sticky-slert .logoclass .icon-with-text,
.logoclass .user-card .bt__img-text-group .text-wrapper .name,
.user-card .bt__img-text-group .text-wrapper .logoclass .name,
.logoclass .notification-list .notification-content .notification-text p,
.notification-list .notification-content .notification-text .logoclass p,
.logoclass .notification-list .notification-content .text-action a,
.notification-list .notification-content .text-action .logoclass a,
.logoclass .notification-list .notification-content .ago-time,
.notification-list .notification-content .logoclass .ago-time {
    margin-top: 3px
}

.toggle-label {
    width: 360px
}

.toggle-label .label {
    display: inline-block;
    position: relative;
    width: 33%;
    color: #454d66;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer
}

.toggle-label .label.active {
    content: " ";
    background: #fff;
    transition: all .2s linear;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16)
}

.btn-group .profile-out img {
    cursor: pointer
}

.court.suspicious,
.court.failed {
    background-image: url(../images/icons/court-suspicious.svg)
}

.court.in-progress,
.court.yet-to-start {
    background-image: url(../images/icons/court-in-progress.svg)
}

.court.clear {
    background-image: url(../images/icons/court-clear.svg)
}

.court.insufficiency {
    background-image: url(../images/icons/court-insufficiency.svg)
}

.form-preview-container {
    margin-top: 4rem
}

.anotherways .sub-heading {
    color: #309975 !important;
    font-size: 13px;
    font-weight: 500
}

.anotherway-icon {
    margin-right: 20px
}

.anotherway-list {
    margin: 30px;
    font-size: 13px;
    color: #808080bf
}

.anotherway-list img {
    width: 25px;
    height: 25px
}

.anotherways span {
    cursor: pointer
}

.companylogoclass {
    width: 120px
}

.batch-tbl td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f5f5;
    color: #454d66;
    font-size: 16px;
    font-weight: 500
}

.admin-row .right-side {
    width: calc(100% - 215px);
    padding: 30px;
    background-color: #fff
}

.cases-set .left-form-elements .form-group {
    width: auto;
    margin-right: 15px
}

.case-filters-wrapper {
    background-color: #f5f5f5;
    padding: 30px 30px 0 30px;
    margin-left: -30px;
    margin-right: -30px
}

.case-tbl .table thead th {
    color: #454d66
}

.case-filters-wrapper .form-control {
    background-color: #fff;
    border: none
}

.navbar .navbar-nav .nav-item.active {
    background: #f5f5f5
}

.navbar .navbar-nav .nav-item.active .nav-link {
    color: #454d66 !important
}

.pac-container.pac-logo:after {
    background-image: none !important;
    height: 0
}

#map {
    min-height: 300px;
    height: 100%
}

.pac-container {
    z-index: 5000
}

.sharp-border {
    border-radius: 0 !important
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
    height: unset !important;
    line-height: unset !important;
    font-size: 13.5px;
    border-radius: 0 !important
}

.bg-gray {
    background-color: #f5f5f5
}

.lrpad20 {
    padding: 0 20px
}

.right-content {
    overflow-x: hidden;
    overflow-y: auto
}

.deep-track {
    margin: 0 !important
}

.close-deep-track {
    cursor: pointer
}

.navbar .navbar-brand img {
    height: 40px
}

.login-bg .login-block {
    background: #ffffffcf;
    border-radius: 4px;
    width: 384px;
    margin: -32px 160px auto auto;
    float: right;
    padding: 40px 30px;
    margin-bottom: 70px;
    position: relative;
    z-index: 1;
    min-height: 300px
}

.view-all {
    text-transform: uppercase;
    cursor: pointer;
    float: right;
    margin-left: auto;
    font-size: 13px;
    text-decoration: none
}

.btn-locate-me {
    padding: 7px 16px;
    min-width: unset
}

.bgvform .react-tel-input input[type=tel] {
    width: unset !important
}

.right-content .card .pack-accordion-head h6 {
    float: left;
    padding: 2rem;
    font-weight: 400
}

.upload-message {
    padding: 20px;
    background-color: #8dbfae;
    color: #000;
    font-size: 16px;
    text-align: center;
    border-radius: 4px
}

#pack-accordion .card .card-body,
.pack-accordion-head[aria-expanded=true] {
    background: #f6f8fb
}

.case-row:hover {
    background-color: #f5f5f5 !important
}

.batch-tbl td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f5f5;
    color: #454d66;
    font-size: 14px;
    font-weight: 500
}

.dashboard-cards {
    background-color: #fff;
    padding: 8px
}

.upload-div {
    background: #f5f5f5;
    border: 1px dashed #bfbfbf
}

page[size=A4] {
    width: 21cm;
    height: 29.7cm;
    position: relative
}

page {
    padding: 0 40px 20px 40px;
    page-break-after: always;
    background: #fff;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px
}

@media print {

    body,
    page {
        margin: 0;
        box-shadow: none;
        background: transparent
    }

    * {
        -webkit-print-color-adjust: exact
    }
}

.page-header,
.page-footer {
    padding: 20px 0
}

.page-header {
    padding-top: 50px
}

.page-footer {
    position: absolute;
    width: 92%;
    left: 4%;
    bottom: 0;
    padding-bottom: 0
}

.doc-title {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400
}

.text-right {
    text-align: right
}

.main-title {
    margin-top: 150px
}

.left-label,
.grey-color {
    color: #707070 !important
}

.font-12 {
    font-size: 13px
}

table {
    width: 100%
}

.left {
    float: left !important
}

.right {
    float: right !important
}

.col-4,
.col-6 {
    float: left;
    position: relative
}

.col-6 {
    width: 50%
}

.col-4 {
    width: 33.33%
}

a {
    text-decoration: none
}

.verified-area {
    display: flex;
    align-items: center;
    justify-content: center
}

.verified-area svg {
    height: 40px
}

.verified-area p {
    font-size: 34px;
    color: #2e744c;
    margin-left: 10px
}

.page-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 30px
}

.user-block {
    margin-top: 60px;
    text-align: center;
    margin-bottom: 150px
}

.user-block h3 {
    margin-bottom: 0
}

.user-block h4 {
    margin-top: 20px;
    font-size: 28px;
    color: #707070
}

.left-label,
.right-label {
    font-size: 15px;
    padding-bottom: 15px
}

ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.block-header {
    margin-top: 30px;
    margin-bottom: 15px
}

.block-header svg {
    fill: #2e744c;
    width: 30px;
    height: 30px;
    float: left
}

.block-header label,
.greenlink {
    color: #2e744c !important
}

.block-header label {
    font-size: 24px;
    margin-left: 10px;
    margin-top: 3px;
    float: left;
    margin-bottom: 10px
}

.block-body1 {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin: 10px 0;
    margin-top: 0
}

.block-group {
    width: 100%;
    display: block;
    margin-bottom: 20px
}

.block-group .greenlink {
    font-weight: 500
}

.seperate-block li {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    margin-bottom: 10px
}

.seperate-block li:last-child {
    margin-bottom: 0
}

.large-title {
    color: #000;
    font-size: 28px
}

.top-header {
    margin-top: 40px
}

.small-title {
    color: #000;
    font-size: 22px;
    margin-top: 15px
}

.extra-small-title {
    font-size: 17px;
    line-height: 30px;
    color: #000
}

.top-header svg {
    margin-top: 50px;
    width: 35px;
    float: right
}

.top-header p {
    font-size: 19px;
    margin-top: 5px;
    color: #2e744c
}

.bold-clear {
    font-weight: 500
}

page .commontbl {
    margin-top: 30px;
    margin-bottom: 50px
}

page .commontbl table {
    border-spacing: 0;
    margin-bottom: 0
}

page .commontbl table th {
    text-align: left;
    color: #000;
    font-weight: 500;
    font-size: 20px;
    border-bottom: 2px solid;
    padding-bottom: 15px
}

page .commontbl table td {
    color: #000;
    font-size: 17px;
    padding: 15px 0
}

page .commontbl table td b {
    font-weight: 500
}

.comment-area {
    font-size: 19px
}

.comment-area b {
    display: block;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px
}

.attach-doc {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px
}

.attach-doc img {
    max-width: 100%;
    max-height: 100%
}

.center-para {
    color: #000;
    font-weight: 500
}

.endreport {
    text-align: center;
    margin-top: 280px
}

.text-center {
    text-align: center
}

page .icon-status {
    display: block;
    height: 34px;
    width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    float: left
}

.greentick {
    background-image: url(../images/icons/greetick.svg);
    height: 54px;
    width: 50px
}

.employment {
    background-image: url(../images/icons/employment.svg)
}

.education {
    background-image: url(../images/icons/education.svg)
}

.reference {
    background-image: url(../images/icons/reference.svg)
}

.residence {
    background-image: url(../images/icons/residence.svg)
}

.id {
    background-image: url(../images/icons/id.svg)
}

.drug {
    background-image: url(../images/icons/drug.svg)
}

.credit {
    background-image: url(../images/icons/credit.svg)
}

.database {
    background-image: url(../images/icons/database.svg)
}

.social {
    background-image: url(../images/icons/social.svg)
}

.employment.suspicious,
.employment.failed {
    background-image: url(../images/icons/employment-suspicious.svg)
}

.education.suspicious,
.education.failed {
    background-image: url(../images/icons/education-suspicious.svg)
}

.reference.suspicious,
.reference.failed {
    background-image: url(../images/icons/reference-suspicious.svg)
}

.residence.suspicious,
.residence.failed {
    background-image: url(../images/icons/residence-suspicious.svg)
}

.id.suspicious,
.id.failed {
    background-image: url(../images/icons/id-suspicious.svg)
}

.drug.suspicious,
.drug.failed {
    background-image: url(../images/icons/drug-suspicious.svg)
}

.credit.suspicious,
.credit.failed {
    background-image: url(../images/icons/credit-suspicious.svg)
}

.database.suspicious,
.database.failed {
    background-image: url(../images/icons/database-suspicious.svg)
}

.social.suspicious,
.social.failed {
    background-image: url(../images/icons/social-suspicious.svg)
}

.employment.in-progress,
.employment.yet-to-start {
    background-image: url(../images/icons/employment-in-progress.svg)
}

.education.in-progress,
.education.yet-to-start {
    background-image: url(../images/icons/education-in-progress.svg)
}

.reference.in-progress,
.reference.yet-to-start {
    background-image: url(../images/icons/reference-in-progress.svg)
}

.residence.in-progress,
.residence.yet-to-start {
    background-image: url(../images/icons/residence-in-progress.svg)
}

.id.in-progress,
.id.yet-to-start {
    background-image: url(../images/icons/id-in-progress.svg)
}

.drug.in-progress,
.drug.yet-to-start {
    background-image: url(../images/icons/drug-in-progress.svg)
}

.credit.in-progress,
.credit.yet-to-start {
    background-image: url(../images/icons/credit-in-progress.svg)
}

.database.in-progress,
.database.yet-to-start {
    background-image: url(../images/icons/database-in-progress.svg)
}

.social.in-progress,
.social.yet-to-start {
    background-image: url(../images/icons/social-in-progress.svg)
}

.employment.clear {
    background-image: url(../images/icons/employment-clear.svg)
}

.education.clear {
    background-image: url(../images/icons/education-clear.svg)
}

.reference.clear {
    background-image: url(../images/icons/reference-clear.svg)
}

.residence.clear {
    background-image: url(../images/icons/residence-clear.svg)
}

.id.clear {
    background-image: url(../images/icons/id-clear.svg)
}

.drug.clear {
    background-image: url(../images/icons/drug-clear.svg)
}

.credit.clear {
    background-image: url(../images/icons/credit-clear.svg)
}

.database.clear {
    background-image: url(../images/icons/database-clear.svg)
}

.social.clear {
    background-image: url(../images/icons/social-clear.svg)
}

.employment.insufficiency {
    background-image: url(../images/icons/employment-insufficiency.svg)
}

.education.insufficiency {
    background-image: url(../images/icons/education-insufficiency.svg)
}

.reference.insufficiency {
    background-image: url(../images/icons/reference-insufficiency.svg)
}

.residence.insufficiency {
    background-image: url(../images/icons/residence-insufficiency.svg)
}

.id.insufficiency {
    background-image: url(../images/icons/id-insufficiency.svg)
}

.drug.insufficiency {
    background-image: url(../images/icons/drug-insufficiency.svg)
}

.credit.insufficiency {
    background-image: url(../images/icons/credit-insufficiency.svg)
}

.database.insufficiency {
    background-image: url(../images/icons/database-insufficiency.svg)
}

.social.insufficiency {
    background-image: url(../images/icons/social-insufficiency.svg)
}

.case-settings {
    margin-top: 70px
}

.case-settings .case-navs {
    justify-content: center
}

.case-settings .viewdetails {
    color: #2e384d;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer
}

.black7 {
    color: #8798ad !important
}

.location svg {
    fill: #8798ad;
    float: left;
    height: 20px;
    margin-top: 5px;
    margin-right: 10px
}

.location p {
    float: left;
    line-height: 25px;
    color: #8798ad;
    width: calc(100% - 30px)
}

.location .credit-card {
    margin-top: 3px
}

.container-case {
    max-width: 70%
}

.black_color label {
    color: #8798ad !important
}

.upload-file {
    height: 250px;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px
}

.upload-file .attachedimg {
    max-height: 100%;
    width: 100%
}

.phonecontact {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background: #f5f5f5;
    font-size: 12px;
    padding: .375rem .75rem;
    display: flex
}

.phonecontact .country-flag {
    cursor: pointer
}

.phonecontact .country-flag .dropdown-menu {
    min-width: auto;
    padding: 0
}

.phonecontact .country-flag .dropdown-menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #e7e7e7;
    min-width: 120px
}

.phonecontact .country-flag .dropdown-menu li:last-child {
    border-bottom: 0
}

.phonecontact .country-flag .dropdown-menu li:hover {
    background: #f5f5f5
}

.phonecontact .country-flag .dropdown-menu li span {
    color: #8798ad;
    margin-left: 5px
}

.phonecontact .country-code {
    align-items: center;
    display: flex;
    border-left: 1px solid #8798ad;
    padding-left: 10px;
    color: #8798ad
}

.phonecontact .contact-number {
    height: 30px;
    font-size: 12px;
    background: transparent;
    box-shadow: none;
    outline: none;
    border: 0;
    margin-left: 7px;
    padding: 0;
    display: flex;
    width: 100%;
    color: #8798ad
}

.phonecontact .imgicons {
    width: 55px;
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-rows: auto;
    align-items: center;
    height: 100%
}

.phonecontact .selected-flag {
    height: 15px
}

.phonecontact .select-arrow {
    margin-left: 3px;
    height: 17px
}

.toggle-tbl .form-group {
    margin: 0
}

.dataset .search-filter .form-control {
    height: 40px;
    background: #f5f5f5
}

.details_tr tr td {
    border: 0;
    padding-top: 0
}

.img-close {
    position: absolute;
    background: #00000059;
    border-radius: 100%;
    top: 10px;
    right: 25px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding: 1px
}

.img-close svg {
    fill: #fff;
    height: 15px
}

.switch {
    height: 24px;
    display: block;
    position: relative;
    cursor: pointer
}

.switch input {
    display: none
}

.switch input+span {
    padding-left: 50px;
    min-height: 24px;
    line-height: 24px;
    display: block;
    color: #fe6666;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    transition: color .3s ease
}

.switch input+span:before,
.switch input+span:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 12px
}

.switch input+span:before {
    top: 0;
    left: 0;
    width: 42px;
    height: 24px;
    background: #fe6666;
    transition: all .3s ease
}

.switch input+span:after {
    width: 18px;
    height: 18px;
    background: #fff;
    top: 3px;
    left: 3px;
    box-shadow: 0 1px 3px rgba(18, 22, 33, .1);
    transition: all .45s ease
}

.switch input+span strong {
    font-weight: normal;
    position: relative;
    display: block;
    top: 1px
}

.switch input+span strong:before,
.switch input+span strong:after {
    font-size: 14px;
    font-weight: 500;
    display: block;
    -webkit-backface-visibility: hidden
}

.switch input+span strong:before {
    content: "No";
    transition: all .3s ease .2s
}

.switch input+span strong:after {
    content: "YES";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    color: #26b789;
    transition: all .3s ease;
    transform: translate(2px, 0)
}

.switch input:checked+span:before {
    background: #26b789
}

.switch input:checked+span:after {
    background: #fff;
    transform: translate(18px, 0)
}

.switch input:checked+span strong:before {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    transform: translate(-2px, 0)
}

.switch input:checked+span strong:after {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: all .3s ease .2s
}

@media(max-width: 1024px) {
    .link-div.btn {
        padding: 8px 0
    }
}

@media(max-width: 991px) {
    .mobile-menu {
        display: block !important
    }

    .navbar {
        padding: 1rem 1.5rem
    }

    .navbar .right-navs {
        display: none
    }

    .navbar .navbar-collapse {
        background: #f3f4f6;
        position: fixed;
        height: 100%;
        width: 0%;
        left: -75%;
        top: 60px;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar .navbar-collapse.show {
        width: 100%;
        left: 0%
    }

    .dashboard-cards {
        display: block
    }

    .dashboard-cards .card {
        width: 100%;
        float: left;
        display: block;
        height: auto;
        margin-bottom: 15px
    }

    .dashboard-cards .card:hover {
        transform: scale(1);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px
    }

    .dashboard-cards .card .link-div {
        display: block;
        position: static;
        top: 0;
        box-shadow: none;
        background: transparent
    }

    .dashboard-cards .card .link-div li {
        border-top: 1px solid #e7e7e7;
        padding-top: 15px
    }

    .dashboard-cards .card .link-div .border-div {
        display: none
    }

    .dashboard-cards .card .media {
        display: block;
        width: 100%;
        padding: 20px;
        padding-bottom: 0
    }

    .dashboard-cards .card .media .clr {
        display: block
    }

    .dashboard-cards .card .media .media-body {
        float: left;
        width: calc(100% - 73px);
        margin-left: 15px
    }

    .dashboard-cards .card .round-icons {
        float: left;
        margin-right: 0
    }

    .cases-set .left-form-elements,
    .cases-set .right-form-elements {
        display: block;
        width: 100%
    }

    .cases-set .left-form-elements .form-group,
    .cases-set .right-form-elements .form-group {
        width: 100%
    }

    .admin-row .side-navbar {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #eceef3;
        padding-top: 70px;
        top: 0;
        left: -100%;
        padding-left: 25px
    }

    .admin-row .right-side {
        width: 100%
    }

    .admin-row .slide-toggle {
        display: block;
        position: absolute;
        right: -50px;
        background: #fff;
        padding: 8px 5px;
        border: 1px solid #ccc;
        border-radius: 4px
    }

    .admin-row .slide-toggle .toggle-wrap .toggle-bar {
        width: 28px
    }

    .admin-row .close-sidemenu {
        display: block
    }

    .single-row-set {
        flex-wrap: wrap;
        flex-direction: row
    }
}

@media(min-width: 768px) {
    .right-half .fade:not(.show) {
        opacity: 1
    }
}

@media(max-width: 768px) {
    .login-bg {
        padding-top: 80px
    }

    .login-bg .btn {
        width: 100%;
        margin-bottom: 0
    }

    .sliderarea .progressbar {
        width: 70%
    }

    .password-block,
    .details-block {
        width: 100%
    }

    .password-block form,
    .details-block form {
        margin-bottom: 40px
    }

    .companylogo {
        width: 80%
    }

    .btn {
        width: 100%;
        margin: 0 0 20px 0 !important
    }

    .tb__side-pop,
    #view-approval {
        width: 100%
    }

    .two-columns li {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .right-navs {
        float: right
    }

    .user-search .form-group {
        width: 100%
    }

    .search-filter {
        width: 100%
    }

    .btn.no-margin-bottom {
        margin-bottom: 0 !important
    }

    .entities-section .container-fluid {
        padding: 0
    }

    .entities-section .inner-form {
        position: relative;
        padding-bottom: 0
    }

    .entities-section .accordion-like-div .select-arrow {
        position: absolute;
        margin: 0;
        right: 15px;
        top: 15px
    }

    .block-center {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 15px
    }

    .tag-list li {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%
    }

    .exceltbl,
    .commontbl {
        width: 100%;
        overflow: auto
    }

    .exceltbl table,
    .commontbl table {
        width: 650px
    }

    .manual-sliderarea .progressbar {
        width: 100%
    }

    .container-fluid.case-card-fill {
        padding: 0
    }

    .container-fluid.case-card-fill .nav-item {
        width: 50%;
        text-align: center;
        white-space: nowrap
    }

    .container-fluid.case-card-fill .toggle-label {
        width: 100%
    }

    .cases-set {
        padding: 30px 0
    }

    .cases-set .tab-content {
        padding: 0 20px
    }

    .insufficiencies_cards {
        padding: 30px
    }

    .cases-block .cases-set {
        padding: 30px
    }

    .manual-details3 .pack-accordion-head {
        padding: 20px 0
    }

    .manual-details3 .pack-accordion-head[aria-expanded=true] {
        padding: 20px 20px
    }

    .manual-details3 .pack-accordion-head h6 {
        font-size: 14px
    }

    .case_initiation_accordion .card .pack-accordion-head>.row {
        display: block
    }

    .case_initiation_accordion .card .pack-accordion-head img {
        position: absolute;
        right: 15px;
        top: 15px
    }

    .all-response-tables .nav-item {
        width: 100%;
        text-align: center
    }

    .all-response-tables .nav-item a {
        padding: 10px;
        margin-bottom: 10px
    }

    .hideusername {
        display: none
    }

    .document-setup {
        background: #fff
    }

    .document-setup .mobile-nav-tabs {
        display: block
    }

    .document-setup .right-half {
        display: none;
        margin-top: 0;
        padding: 0
    }

    .document-setup .right-half .right-content {
        margin-top: 0;
        padding: 0
    }

    .document-setup .right-half .col-12 {
        padding: 0
    }

    .document-setup .right-half .card .pack-accordion-head img {
        margin-top: 9px;
        position: absolute;
        right: 20px
    }

    .document-setup .right-half .card .pack-accordion-head h6 {
        float: left;
        padding: 1rem;
        font-size: 14px
    }

    .document-setup .right-half .btn {
        width: 85%
    }

    .document-setup .tab-content {
        margin: 0
    }

    .document-setup .left-content {
        margin-top: 0;
        padding: 1rem;
        background: #fff
    }

    .document-setup .error-docs {
        background: #f6f8fb
    }

    .document-setup .left-half.active,
    .document-setup .right-half.active {
        display: block
    }

    .document-setup .left-half,
    .document-setup .right-half {
        display: none
    }

    .document-setup .left-half {
        padding-right: 15px
    }

    .document-setup .left-content,
    .document-setup .right-content {
        height: auto !important;
        overflow: visible
    }

    .document-setup .full-side {
        margin: 0
    }

    .add-document-modal .modal-dialog {
        max-width: 100%
    }

    .add-document-modal .modal-dialog .modal-footer {
        padding: 0 2rem 0;
        display: block
    }

    .admin-main-wrapper .users-wrapper .card-list {
        width: 100%
    }

    .entity-header .bt__label-group {
        margin-left: 0 !important;
        margin-top: 10px
    }

    .notification-content .text-center {
        text-align: left !important
    }

    .action-icons {
        float: left;
        width: auto;
        display: flex;
        flex: 0 0 auto
    }

    .select-icon {
        float: right;
        flex: auto;
        width: auto
    }

    .sidepop_header h6 {
        font-size: 16px
    }

    .notification-list .notification-content .bt_col {
        width: 100%
    }

    .social-intelligence-wrapper .search-wrapper .form-group {
        width: 80%
    }

    .single-row-set .d-flex {
        margin: 0 0 20px;
        flex-direction: column
    }

    .inside-case-initiation .left-content,
    .inside-case-initiation .right-content {
        height: auto !important
    }

    .inside-case-initiation {
        margin: 0
    }

    .inside-case-initiation .right-content,
    .inside-case-initiation .right-half {
        padding: 0
    }

    .inside-case-initiation .card .pack-accordion-head h6 {
        float: left;
        padding: 1rem;
        font-size: 14px
    }

    .inside-case-initiation .left-content {
        padding: 0 30px 0 15px
    }
}

@media(max-width: 600px) {
    .dashboard-cards .view-all {
        display: block;
        width: 100%;
        margin-bottom: 15px;
        text-align: center
    }

    .social-intelligence-wrapper .social-action {
        margin-top: 30px
    }

    .social-intelligence-wrapper .social-action li {
        margin-right: 20px
    }

    .social-intelligence-wrapper .social-action li span {
        width: 40px;
        height: 40px
    }

    .graph-legends {
        margin-top: 50px;
        justify-content: center;
        display: flex
    }

    .flex-cards {
        display: block
    }

    .flex-cards .card-content {
        margin: 0;
        margin-bottom: 30px
    }
}

@media(max-width: 500px) {
    .login-bg .login-block {
        width: 80%;
        padding: 40px 30px
    }

    .sliderarea .progressbar {
        width: 90%
    }
}

@media(max-height: 600px) {
    .terms-of-use {
        position: relative;
        margin-bottom: 20px
    }
}

.exceltbl .react-tel-input input[type=tel] {
    width: unset !important;
}