.react-datepicker-popper {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    background: #fff;
    left: -30px !important;
    z-index: 14;
}

.react-datepicker {
     border: 0px;
 }

 .react-datepicker__time-container {
     border: 0px;
 }

 .react-datepicker__header {
     border: 0px;
     background: #fff;
 }

 .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
     display: none;
 }

 .react-datepicker__header__dropdown--select select{
     background: #fff;
     border: 0px;
     font-size: 1rem;
     width: 100%;
     background-size: contain;
 }

 .react-datepicker__month-dropdown-container select{
     background-position: 74px 1px !important;
 }

 .react-datepicker__year-dropdown-container select{
     background-position: 45px 1px !important;
     width: 70px;
 }

 .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
     display: none;
 }

 .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
     display: flex;
     justify-content: space-between;
     padding: 7px 12px;
     margin-bottom: 10px;
 }

 .react-datepicker__month-dropdown-container{
      flex: 0  0 65%;
      max-width: 65%;
 }

  .react-datepicker__year-dropdown-container{
      flex: 0  0 30%;
      max-width: 30%;
 }

 .react-datepicker {
    border: 0px;
    display: flex;
}

 .react-datepicker__day-name {
     width: 30px;
     font-size: .9em;
     font-weight: 500;
     color: #9e9e9e;
     margin: 0px
 }

 .react-datepicker__day {
     font-size: .81rem;
     height: 25px;
     width: 25px;
 }

 .react-datepicker__week .react-datepicker__day {
     display: inline-flex;
     align-items: center;
     justify-content: center;
 }


 .react-datepicker-time__header {
     font-size: 1rem;
     background-size: contain;
     font-weight: 100;
     height: 35px;
     display: flex;
     padding: 0px;
     vertical-align: bottom;
     width: 100%;
     align-items: center;
     padding-left: 15px;
 }

 .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
     height: 170px !important;
 }


 .react-datepicker__month-select , .react-datepicker__year-select{
      outline: none;
 }
 .react-datepicker__header__dropdown--select select , .react-datepicker-time__header {
     color: #8e8e8e;
 }


 .react-datepicker-wrapper{
    width: 100%;
}

.react-datepicker__close-icon::after{
    background-color:#bfbfbf;
    outline: none;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
    border-radius: 2.3rem;
    outline: none;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    outline: none;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover{
    border-radius: 2.3rem;
    outline: none;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover{
    border-radius: 2.3rem;
    outline: none;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
    border-radius: 2.3rem;
    outline: none;
}

